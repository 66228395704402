import { Box, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/layout';
import * as React from 'react';
import { heroImage } from '../common/Assets';

const subtitles = {
  social: {
    main: 'So you want to throw a surprise birthday party for that special person in your life, an anniversary celebration; a baby shower, perhaps a bridal shower or you just want to hang out with your favourite people with good food and drink in a fabulous venue? We will plan it, send invitations on your behalf, provide a venue with exquisite décor and of course, great food! We also cater for cocktail parties, family reunions, memorials, graduation parties, engagement parties, charity events, retirement parties, themed parties and intimate sit-down dinners. Contact us for full service today.',
    mobile:
      'Let us help you plan your event, send invitations on your behalf, and provide a venue with exquisite décor and of course, great food! We cater for cocktail parties, family reunions, memorials, graduation parties, engagement parties, charity events, retirement parties, themed parties and intimate sit-down dinners. Contact us for full service today.',
  },
  weddings: {
    main: 'We understand more than most how much this day matters to you. You not only want to make it special, memorable and joyful, you want it to leave a lasting impression; be able to relive and share the beautiful memories for years to come; you want it to reflect who you are as an individual and to be flawless! We got you! Our team of professional chefs, wedding planners, professional photographers and the choice of great venues will provide you impeccable service that will ensure enduring memories for years to come. Contact us to book a personalised food tasting for your wedding day menu so you’re assured of our great service on the day.',
    mobile:
      'Our team of professional chefs, wedding planners, professional photographers and the choice of great venues will provide you impeccable service that will ensure enduring memories for years to come. Contact us to book a personalized food tasting for your wedding day menu so you’re assured of our great service on the day.',
  },
  corporate: {
    main: 'We provide lunches for any size corporate office or factory - pre-packed or buffet - your choice! We work with you to design a menu of your choice and offer a variety of set menus so that lunchtime remains a joyous occasion. We also cater for corporate events and work hard to make you look good every time you want to impress a prospective customer; celebrate a much appreciated customer or employee or host an event for your favourite charity. We will give you an individualised and flawless service that will help you create a lasting impression, whatever the event! Contact us now!',
    mobile:
      'We provide lunches for any size corporate office or factory - pre-packed or buffet - your choice! We work with you to design a menu of your choice and offer a variety of set menus so that lunchtime remains a joyous occasion. We also cater for corporate events and we will give you an individualized and flawless service that will help you create a lasting impression, whatever the event! Contact us now!',
  },
  caterToGo: {
    main: 'Do you have a few friends to impress with “your”culinary skills? Or perhaps a quick get-together with friends and family? Or you just don’t have enough time to invest cooking for that special dinner party? Allow us to make you look good at the drop of a hat! We will prepare your special meal in advance and all you have to do is heat it up and serve! They will rave about your cooking without you breaking a sweat…no meal is too small, design your menu from our special selection.',
    mobile:
      'Do you have a quick get-together with friends and family? Or you don’t have enough time to cook for that special dinner party? Allow us to prepare your special meal in advance and all you have to do is heat it up and serve! Your guests will rave about the food without you breaking a sweat…no meal is too small, design your menu from our special selection.',
  },
};

const EventHeader = ({
  title,
  subtitle,
  backgroundImage = heroImage,
}: {
  title: string;
  subtitle: string;
  backgroundImage?: string;
}) => {
  return (
    <Flex
      w={'full'}
      // h={'0vh'}
      minH={{ base: '200px', md: '400px' }}
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${backgroundImage})`}
      backgroundSize={'cover'}
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
      backgroundPosition={'center center'}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={{ base: 4, md: 8 }}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
      >
        <Stack maxW={'4xl'} align={'flex-start'} spacing={6} textAlign="center" paddingBottom="6" paddingTop="6">
          <Heading
            as="h1"
            width="full"
            color={'white'}
            fontWeight={200}
            lineHeight={1.2}
            textAlign="center"
            textStyle="heading"
            fontFamily="Playfair Display"
            fontSize={{ base: '3xl', md: '6xl' }}
          >
            {title}
          </Heading>
          <Text
            width="full"
            textAlign="center"
            color={'white'}
            fontFamily="Playfair Display"
            lineHeight={1.2}
            fontSize={{ base: '2xl', md: '3xl' }}
          >
            {subtitle}
          </Text>
          <Box>
          <Text
            color="white"
            display={{ base: 'none', md: 'contents' }}
          >
            {
              {
                Weddings: subtitles.weddings.main,
                Social: subtitles.social.main,
                Corporate: subtitles.corporate.main,
                CatertoGo: subtitles.caterToGo.main,
              }[title.replace(/\s/g,"")]
            }
          </Text>
          <Text
            color="white"
            fontSize="xs"
            display={{ base: 'contents', md: 'none' }}
          >
            {
              {
                Weddings: subtitles.weddings.mobile,
                Social: subtitles.social.mobile,
                Corporate: subtitles.corporate.mobile,
                CatertoGo: subtitles.caterToGo.mobile,
              }[title.replace(/\s/g,"")]
            }
          </Text>
          </Box>
        </Stack>
      </VStack>
    </Flex>
  );
};

export { EventHeader };