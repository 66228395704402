import { Box, Flex, Text } from '@chakra-ui/layout';
// import { useContext } from 'react';
// import { getBannerImageByName } from '../../../common/ImageHelpers';
import { Header } from '../../../components/Header';
import { Page } from '../../../components/Page';
// import { BannerContext } from '../../../layouts/MainLayout';
import aboutbg from "../../../assets/images/aboutbg.webp"
import bannerbg from "../../../assets/images/delivery.jpg"

export const About = () => {
//   const banners = useContext(BannerContext);
  return (
    <Page title="About Cater4U">
      <Box>
        <Header
          title="About Us"
          subtitle=""
          backgroundImage={bannerbg}
        />
        <Flex w={{ base: '100%', md: '80%' }} mt="50" mx="auto" flexWrap="wrap" boxShadow="sm" backgroundImage={aboutbg} backgroundSize="cover">
          <Box w={{ base: '100%', md: '50%' }} p={{base:"6",md:"20"}} fontFamily="Playfair Display" fontSize={{base:"sm",md:"xl"}} color="white">
            <Text>
              Cater4 U was born out of a passionate love for food - the
              ingredients; the smells and flavours; the flair and creativity of
              presentation and the absolute joy and warmth that food brings to
              any gathering. At Cater4 U, we are so passionate about our food
              that we hold ourselves to exceptionally high standards that focus
              on giving you individualised flawless service at every touch point
              - we CATER 4U! For us, good food evokes warm feelings of family,
              love, joy and celebration, and out of this love for creating
              joyful memories and lasting impressions, we have an unwavering
              commitment to personalised and excellent customer service.
            </Text>
            <Text mt="10">
              As an owner-run business, our attention to detail is famed! We
              take great pride in catering to your personal taste and needs, and
              to delivering services that make you look good, whether it’s a
              wedding, corporate event, party or just great food-to-go. Our team
              of qualified, creative chefs; event planners; and waiting staff,
              will ensure your special day is flawless. Go on…try us and join
              our growing list of clients who rave about us.
            </Text>
          </Box>
          <Box width="50%">

          </Box>
        </Flex>
      </Box>
    </Page>
  );
};
