import { Link } from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom"
import * as React from "react"

interface ComponentProps{
    url: string,
    children: React.ReactChild|React.ReactChildren
}

const NavLink = ({ url, children }:ComponentProps) => (
    <Link
      px={4}
      py={2}
      rounded={'md'}
      position="relative"
      transition="all .8s"
      _hover={{
        color: '#05A551',
        _after: {
          width: '100%',
        },
      }}
      _after={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '0%',
        content: "'.'",
        color: 'transparent',
        background: '#05A551',
        height: '1px',
        transition: 'all .8s',
      }}
      _focus={{
        boxShadow: 'none'
      }}
      to={url}
      as={RouterLink}
    >
      {children}
    </Link>
  );

  export default NavLink