import { axiosInstance } from '../common/API';
const BASE_PATH = 'hireitems';

interface DataType {
  [key: string]: string | boolean | number;
}

function getHireItem(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getHireItems() {
  return axiosInstance.get(`${BASE_PATH}`);
}

function storeHireItem(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateHireItem(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteHireItem(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function uploadHireItemImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getHireItem,
  getHireItems,
  storeHireItem,
  updateHireItem,
  deleteHireItem,
  uploadHireItemImage,
};
