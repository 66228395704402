import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  components: {
    Steps,
  },
  styles: {
    fonts: {
      heading: 'Playfair Display',
      body: 'Lexend',
    },
    global: {
      "html, body": {
        // backgroundColor: "gray.900"s
        fontFamily: "Open Sans",
        borderColor: "gray.500"
      },
      '.swal-modal': {
        backgroundColor: 'gray.900',
        color: 'white',
      },
      '.swal-title': {
        color: 'white',
      },
      '.swal-text':{
        color:"white"
      },
      '.swal-icon--success:after, .swal-icon--success:before':{
        backgroundColor: 'gray.900',
      },
      '.swal-icon--success::before,.swal-icon--success::after,.swal-icon--success__hide-corners':{
        backgroundColor: 'gray.900',
      },
    },
  },
});

export default theme;
// extend theme to set default to dark
// const config = {

// };

// const overrides = {
//   config,

// };

// export default extendTheme(overrides);
