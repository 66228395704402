import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Box, Button, Icon } from '@chakra-ui/react';
import * as React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import { ListEventsCard } from './ListEventsCard';

const ListEventsPage = () => {
  return (
    <Page title="list events">
      <Box>
      <Flex>
        <Text fontSize="2xl" fontWeight="bold">
          Events
        </Text>
        <Spacer />
        <Link to={RouteNames.admin.events+"/create"}>
          <Button colorScheme="green" size="sm">
            <Icon as={FiPlus} mr="1" /> Add Event
          </Button>
        </Link>
      </Flex>
      <ListEventsCard />
      </Box>
    </Page>
  );
};

export default ListEventsPage;
