import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Box, Button, Icon } from '@chakra-ui/react';
import * as React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import { ListProductsCard } from './ListProductsCard';

const ListProductsPage = () => {
  return (
    <Page title="list products">
      <Box>
      <Flex>
        <Text fontSize="2xl" fontWeight="bold">
          Products
        </Text>
        <Spacer />
        <Link to={RouteNames.admin.products+"/create"}>
          <Button colorScheme="green" size="sm">
            <Icon as={FiPlus} mr="1" /> Add Product
          </Button>
        </Link>
      </Flex>
      <ListProductsCard />
      </Box>
    </Page>
  );
};

export default ListProductsPage;
