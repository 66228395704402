import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MainLayout } from './layouts/MainLayout';
import { AuthLayout } from './layouts/AuthLayout';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/base.scss';
import theme from './theme';
import { AdminLayout } from './layouts/AdminLayout';
import { QueryClient, QueryClientProvider } from 'react-query';
import ScrollToTop from './common/ScrollToTop';

const history = createBrowserHistory();
const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <ToastContainer />
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {/* add routes with layouts */}
            <Route path="/admin">
              <AdminLayout />
            </Route>
            <Route path="/auth">
              <AuthLayout />
            </Route>
            <Route path="">
              <MainLayout />
            </Route>
            {/* if all else fails add redirect for first page */}
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </ChakraProvider>
    </HelmetProvider>
  </QueryClientProvider>
);

export default App;
