// import { Box, Wrap, WrapItem, Flex, Select } from '@chakra-ui/react';

// import { Page } from '../../../components/Page';
// import { useEffect, useState } from 'react';
// import { getProducts } from '../../../services/products.service';
// import ProductCard from './ProductCard';
// import { getCategories } from '../../../services/categories.service';
// // import { roundToDecimalPlaces } from '../../../common/Helpers';
// import { Header } from '../../../components/Header';
// import { IProduct } from '../../../interfaces/ProductInterfaces';
// import { CategoriesSideBar } from '../../../components/sidebars/CategoriesSidebar';
// import { ICategory } from '../../../interfaces/CategoryTypes';

// // TODO track which product is selected
// // interface IProduct {
// //   id: number;
// //   name: string;
// //   price: number;
// //   description: string;
// //   image: string;
// //   category_id: number | null;
// //   count: number;
// // }

// // interface ICart {
// //   name: string;
// //   phone: string;
// //   total: number;
// //   products: Array<IProduct>;
// // }

// // const initialState = { name: '', phone: '', products: [] };

// // function reducer(state: any, action: any) {
// //   switch (action.type) {
// //     case 'increment':
// //       let cart = { ...state };
// //       var product = action.product;
// //       // declare a new products array to kill the pass by reference which will accidentally update the state
// //       // do not dare to use cart.products!!!!
// //       // strictmode runs twice showing it to produce different results not a bug its a feature it means your shit is busted
// //       let products = cart.products;

// //       // seacrch for index of product with same id as new product
// //       // var getIndex = cart.products.findIndex(
// //       //   (e: any) => e.id === action.product.id
// //       // );

// //       // // if a product with the id does exist update it otherwise push in a new product into porducts array
// //       // if (getIndex >= 0) {
// //       //   products = products.map((p: any) => {
// //       //     return p.id === product.id ? { ...p, count: p.count++ } : p;
// //       //   });
// //       // } else {
// //       //   products.push({ ...product, count: 1 });
// //       // }
// //       let products1 = products.map((p: any) => {
// //         return p.id === product.id ? { ...p, count: p.count++ } : p;
// //       });

// //       // if no change that means empty products array so push in new value
// //       if (JSON.stringify(products) === JSON.stringify(products1)) {
// //         products1.push({ ...product, count: 1 });
// //       }

// //       return { ...state, products: products1, total: cartTotalCalc(products1) };

// //     case 'decrement':
// //       let d_cart = { ...state };
// //       var d_id = action.d_id;
// //       // declare a new products array to kill the pass by reference which will accidentally update the state
// //       // do not dare to use cart.products!!!!
// //       // strictmode runs twice showing it to produce different results not a bug its a feature it means your shit is busted
// //       let d_products = d_cart.products;

// //       // seacrch for index of product with same id as new product
// //       // var getIndex = cart.products.findIndex(
// //       //   (e: any) => e.id === action.product.id
// //       // );

// //       // // if a product with the id does exist update it otherwise push in a new product into porducts array
// //       // if (getIndex >= 0) {
// //       //   products = products.map((p: any) => {
// //       //     return p.id === product.id ? { ...p, count: p.count++ } : p;
// //       //   });
// //       // } else {
// //       //   products.push({ ...product, count: 1 });
// //       // }

// //       let d_products1 = d_products.filter((p: any) => {
// //         return p.id !== d_id;
// //       });

// //       // if no change that means empty products array so push in new value
// //       // if (JSON.stringify(d_products) === JSON.stringify(d_products1)) {
// //       //   products1.push({ ...d_product, count: 1 });
// //       // }

// //       return {
// //         ...state,
// //         products: d_products1,
// //         total: cartTotalCalc(d_products1),
// //       };
// //     default:
// //       throw new Error();
// //   }
// // }

// // function cartTotalCalc(products: Array<IProduct>) {
// //   let total = 0;
// //   products.forEach(product => {
// //     total = total + product.price * product.count;
// //   });
// //   return roundToDecimalPlaces(total, 2);
// // }

// export const Shop = ({
//   filterName = 'shop',
//   title = 'Shop',
//   subtitle = 'Spice up your Cooking with our authentic flavours',
// }) => {
//   // const [products, setProducts] = useState<IProduct[]>([]);
//   const [categories, setCategories] = useState<ICategory[]>([]);
//   const [products, setProducts] = useState<IProduct[]>([]);
//   const [filtered, setFiltered] = useState<IProduct[]>([]);

//     const filter = (category_id: number) => {
//       //console.log('filtering',!isNaN(category_id))
//       if (!isNaN(category_id)) {
//         const results = products.filter(product => {
//           return product.category_id === category_id;
//           // Use the toLowerCase() method to make it case-insensitive
//         });
//         setFiltered(results);
//       } else {
//         setFiltered(products);
//         // If the text field is empty, show all users
//       }
//     };

//   useEffect(() => {
//     getProducts(filterName)
//       .then(repsonse => {
//         setProducts(repsonse?.data?.data);
//         setFiltered(repsonse?.data?.data);
//       })
//       .catch();

//     getCategories(filterName).then(response => {
//       setCategories(response?.data?.data);
//     });

//     // //console.log(cart);
//   }, [filterName]);

//   return (
//     <Page title="Amazing Catering in Harare">
//       <Box>
//         <Header title={title} subtitle={subtitle} />
//         <Box w="" mx={{ base: '1', md: '1' }} my="10">
//           <Flex w="100%" justify="center" px="10">
//             <Box w="20%">
//               {/* <CategoriesSideBar categories={categories}/> */}
//               <Box backgroundColor="gray.200" p="4">
//                 <Select
//                   placeholder="All Categories"
//                   isRequired
//                   borderRadius="0"
//                   backgroundColor="white"
//                   onChange={(e)=>filter(parseInt(e.target.value))}
//                 >
//                   {categories.map((category,i)=>{
//                     return <option value={category.id}>{category.name}</option>
//                   })}
//                 </Select>
//               </Box>
//             </Box>
//             <Flex w="80%" wrap="wrap">
//               {filtered.map((product: IProduct, i) => {
//                 return (
//                   <Box key={i} w={{ base: '100%', md: '33%' }} px="2" my="2">
//                     <ProductCard
//                       id={product.id}
//                       category_id={product.category_id}
//                       quantity={0}
//                       price={product.price}
//                       name={product.name}
//                       description={product.description}
//                       image={product.image}
//                     ></ProductCard>
//                   </Box>
//                 );
//               })}
//             </Flex>
//           </Flex>
//         </Box>
//       </Box>
//     </Page>
//   );
// };

// // function Rating({ rating, numReviews }) {
// //   return (
// //     <Box d="flex" alignItems="center">
// //       {Array(5)
// //         .fill('')
// //         .map((_, i) => {
// //           const roundedRating = Math.round(rating * 2) / 2;
// //           if (roundedRating - i >= 1) {
// //             return (
// //               <Icon
// //                 as={FiStar}
// //                 key={i}
// //                 style={{ marginLeft: '1' }}
// //                 color={i < rating ? 'teal.500' : 'gray.300'}
// //               />
// //             );
// //           }
// //           if (roundedRating - i === 0.5) {
// //             return <Icon as={FiStar} key={i} style={{ marginLeft: '1' }} />;
// //           }
// //           return <Icon as={FiStar} key={i} style={{ marginLeft: '1' }} />;
// //         })}
// //       <Box as="span" ml="2" color="gray.600" fontSize="sm">
// //         {numReviews} review{numReviews > 1 && 's'}
// //       </Box>
// //     </Box>
// //   );
// // }
import * as React from 'react';
import { ShopCard } from '../../../components/cards/ShopCard';
import { Page } from '../../../components/Page';

export const Shop = () => {
  return (
    <Page title="Amazing Catering in Harare">
      <ShopCard
        filterName="shop"
        title="Shop"
        subtitle="Spice up your Cooking with our authentic flavours"
      />
    </Page>
  );
};
