import { axiosInstance } from '../common/API';
import { get, remove, store } from '../common/Storage';

const BASE_PATH = 'auth';

function loginUser(data:any) {
  return axiosInstance.post(`${BASE_PATH}/login`, { ...data });
}

function setCurrentUser(data:string, remember = false) {
  logoutUser();
  store('a_token', data, remember ? 'local' : 'session');
}

// remove the auth token from storageKey.. this function is not resonsible for kicking someone out
function logoutUser() {
  remove({key:'a_token'});
}

function getAuthToken() {
  return get('a_token', 'session') || get('a_token', 'local');
}

export { loginUser, setCurrentUser, logoutUser, getAuthToken };
