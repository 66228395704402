import { FiBox, FiDollarSign, FiHome,  FiMapPin,  FiMenu, FiShoppingCart, FiTag } from "react-icons/fi";
import { RouteNames } from "../../common/RouteNames";

const Links = [
  { name: 'Dashboard', link: '/admin/dashboard', icon: FiHome },
  { name: 'Menus', link: RouteNames.admin.menus, icon: FiMenu },
  { name: 'Events', link: '/admin/events', icon: FiMapPin },
  { name: 'Hire Items', link: '/admin/hireitems', icon: FiBox },
  { name: 'Categories', link: '/admin/categories', icon: FiTag },
  { name: 'Products', link: '/admin/products', icon: FiDollarSign },
  { name: 'Orders', link: '/admin/orders', icon: FiShoppingCart },
  {name: 'DeliveryOptions',link:'/admin/delivery-options',icon: FiMapPin}
  // { name: 'Cater-To-Go', link: '/admin/products', icon: FiTruck },
];

export default Links;
 