import { Box, Flex, Image} from "@chakra-ui/react";
import * as React from "react";
import logo from '../assets/images/cater4u.webp';
import { AuthRoutes } from "../routes/AuthRoutes";

export const AuthLayout = () => {
  return (
    <Box>
      <Flex
        ml={{ base: 0, md: 0 }}
        mt="4"
        px={{ base: 4, md: 8 }}
        height="20"
        alignItems="center"
        justifyContent={{ base: "space-between", md: "space-between" }}
      >
        <Image src={logo} width={{ base: '', md: '250px' }} maxW="100%" />
      </Flex>
      {/* page content is loaded here*/}
      <AuthRoutes />
    </Box>
  );
};
