import * as React from 'react';
import { Route, Switch } from 'react-router';
import { RouteNames } from '../common/RouteNames';
import { EditEventPage } from '../pages/admin/events/EditEventPage';
import ListEventsPage from '../pages/admin/events/ListEventsPage';
import { CreateCoursePage } from '../pages/admin/courses/AddCoursePage';
import { EditCoursePage } from '../pages/admin/courses/EditCoursePage';
import { Dashboard } from '../pages/admin/dashboard/Dashboard';
import { CreateMenuPage } from '../pages/admin/menus/CreateMenuPage';
import { EditMenuPage } from '../pages/admin/menus/EditMenuPage';
import ListMenusPage from '../pages/admin/menus/ListMenusPage';
import { CreateHireItemPage } from '../pages/admin/hireitems/CreateHireItemPage';
import { EditHireItemPage } from '../pages/admin/hireitems/EditHireItemPage';
import ListHireItemsPage from '../pages/admin/hireitems/ListHireItemsPage';
import { CreateProductPage } from '../pages/admin/products/AddProductPage';
import { EditProductPage } from '../pages/admin/products/EditProductPage';
import ListProductsPage from '../pages/admin/products/ListProductsPage';
import { CreateCategoryPage } from '../pages/admin/categories/AddCategoryPage';
import { EditCategoryPage } from '../pages/admin/categories/EditCategoryPage';
import ListCategoriesPage from '../pages/admin/categories/ListCategoriesPage';
import { ViewOrderPage } from '../pages/admin/orders/ViewOrderPage';
import ListOrdersPage from '../pages/admin/orders/ListOrdersPage';
import { EditBannerPage } from '../pages/admin/banners/EditBanners';
import { CreateDeliveryOptionsPage } from '../pages/admin/deliveryoptions/CreateDeliveryOptionPage';
import ListDeliveryOptionsPage from '../pages/admin/deliveryoptions/ListDeliveryOptionsPage';
import { EditDeliveryOptionPage } from '../pages/admin/deliveryoptions/EditDeliveryOptionPage';

export const AdminRoutes = () => {
  return (
    <Switch>
      <Route path={`${RouteNames.admin.menus}/create`} exact component={CreateMenuPage} />
      <Route path={`${RouteNames.admin.menus}/:id`} exact component={EditMenuPage} />
      <Route path={`${RouteNames.admin.menus}/:menu_id/courses`} exact component={CreateCoursePage} />
      <Route path={`${RouteNames.admin.menus}/:menu_id/courses/:course_id`} exact component={EditCoursePage} />
      <Route path={RouteNames.admin.menus} exact component={ListMenusPage} />

      <Route path={`${RouteNames.admin.events}`} exact component={ListEventsPage} />
      <Route path={`${RouteNames.admin.events}/:event_id`} exact component={EditEventPage} />

      <Route path={`${RouteNames.admin.hireitems}/create`} exact component={CreateHireItemPage} />
      <Route path={`${RouteNames.admin.hireitems}/:id`} exact component={EditHireItemPage} />
      <Route path={`${RouteNames.admin.hireitems}`} exact component={ListHireItemsPage} />

      <Route path={`${RouteNames.admin.categories}/create`} exact component={CreateCategoryPage} />
      <Route path={`${RouteNames.admin.categories}/:id`} exact component={EditCategoryPage} />
      <Route path={`${RouteNames.admin.categories}`} exact component={ListCategoriesPage} />

      <Route path={`${RouteNames.admin.products}/create`} exact component={CreateProductPage} />
      <Route path={`${RouteNames.admin.products}/:id`} exact component={EditProductPage} />
      <Route path={`${RouteNames.admin.products}`} exact component={ListProductsPage} />

      <Route path={`${RouteNames.admin.deliveryoptions}/create`} exact component={CreateDeliveryOptionsPage} />
      <Route path={`${RouteNames.admin.deliveryoptions}/:id`} exact component={EditDeliveryOptionPage} />
      <Route path={`${RouteNames.admin.deliveryoptions}`} exact component={ListDeliveryOptionsPage} />

      <Route path={`${RouteNames.admin.orders}`} exact component={ListOrdersPage} />
      <Route path={`${RouteNames.admin.orders}/:id`} exact component={ViewOrderPage} />

      <Route path={`${RouteNames.admin.banners}/:id`} exact component={EditBannerPage} />
      {/* <Route path={`${RouteNames.admin.events}/create`} exact component={ListEventsPage} /> */}
      {/* <Route path={`${RouteNames.admin.courses}/create/:menu_id`} exact component={CreateMenuPage} /> */}

      {/* 
            <Route path="/admin/menus/:menu_id/variants/add" exact component={CreateVariantPage}/>
            <Route path="/admin/menus/:menu_id/variants/:id" exact component={EditVariantPage}/>

            <Route path="/admin/brands/add" exact component={CreateBrandPage}/>
            <Route path="/admin/brands/:id" exact component={EditBrandPage}/>
            <Route path="/admin/brands" exact component={ListBrandsPage}/>

            <Route path="/admin/categories/add" exact component={CreateCategoryPage}/>
            <Route path="/admin/categories/:id" exact component={EditCategoryPage}/>
            <Route path="/admin/categories" exact component={ListCategoriesPage}/>

            <Route path="/admin/banners/:bannertag_id" exact component={ListBannersPage}/>
            <Route path="/admin/banners/add/:bannertag_id" exact  component={CreateBannerPage}/>
            <Route path="/admin/banners/edit/:id" exact component={EditBannerPage}/> */}

      <Route path="*" component={Dashboard} />
    </Switch>
  );
};
