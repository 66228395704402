import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList} from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import ViewOrderCard from './ViewOrderCard';

export const ViewOrderPage = () => {
  let { id }: { id: any } = useParams();

  return (
    <Page title="add order">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            View Order
          </Text>
          <Spacer />
          <Link to={RouteNames.admin.orders}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <Flex width="100%" mt="8" wrap="wrap">
          <Box width={{ base: '100%', md: '100%' }}>
            <ViewOrderCard id={id} mx="2" />
          </Box>
        </Flex>
      </Box>
    </Page>
  );
};
