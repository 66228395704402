import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/layout';

import { Field, FieldProps, Form, Formik } from 'formik';
import * as React from 'react';
import { toast } from 'react-toastify';

import Card from '../../../components/Card';
import { Page } from '../../../components/Page';
import * as Yup from 'yup';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import { Button } from '@chakra-ui/button';
import { getEvents } from '../../../services/events.service';
import { IEvent } from '../../../interfaces/EventInterfaces';
import { Textarea } from '@chakra-ui/textarea';
import { Header } from '../../../components/Header';
import { RootStateOrAny, useSelector } from 'react-redux';
import { HireItem } from './HireItem';
import { IHireItem } from '../../../interfaces/HireItemsInterface';
import { storeQuotation } from '../../../services/quotations.service';
import { clearCart } from '../../../slices/HireItemsSlice';
import store from '../../../common/store';
import { BannerContext } from '../../../layouts/MainLayout';
import { getBannerImageByName } from '../../../common/ImageHelpers';
import { clearForm, updateForm } from '../../../slices/ContactFormSlice';
import { IContactForm } from '../../../interfaces/ContactDetailsTypes';
import { Checkbox } from '@chakra-ui/checkbox';
import { useQuery } from 'react-query';
import { IMenu } from '../../../interfaces/MenuInterfaces';
import Loader from '../../../components/Loader';
import { useHistory } from 'react-router';

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string().required('Required').min(1),
  whatsapp: Yup.string().required('Required').min(1),
});

interface MyFormValues {
  name: string;
  email: string;
  phone: string;
  whatsapp: string;
  event_id?: string;
  menu_id?: string;
  venue: string;
  date: string;
  attendees: number;
  comments: string;
}

const ContactUs = () => {
  const [loading, setLoading] = React.useState(false);
  const cart = useSelector((state: RootStateOrAny) => state);
  const [eventID, setEventID] = React.useState<number>();
  const [formKey, setFormKey] = React.useState(0);
  const banners = React.useContext(BannerContext);
  const [catering, setCatering] = React.useState(false);
  const [hiring, setHiring] = React.useState(false);
  const history = useHistory();
  const [menus, setMenus] = React.useState<Array<IMenu>>();

  const initialValues: MyFormValues = {
    name: cart.ContactFormSlice.name,
    email: cart.ContactFormSlice.email,
    phone: cart.ContactFormSlice.phone,
    whatsapp: cart.ContactFormSlice.whatsapp,
    attendees: cart.ContactFormSlice.attendees,
    event_id: undefined,
    menu_id: undefined,
    venue: cart.ContactFormSlice.venue,
    date: cart.ContactFormSlice.date,
    comments: cart.ContactFormSlice.comments,
  };

  function updateFormStore(data: IContactForm) {
    store.dispatch(updateForm({ data }));
  }

  const { isLoading, data } = useQuery('events', getEvents);

  React.useEffect(() => {
    if (cart.HireItemsSlice.length > 0) setHiring(true);
    setEventID(cart.ContactFormSlice.event_id);
  }, [cart, eventID]);

  async function submit(data: any) {
    setLoading(true);
    var hireitems = cart.HireItemsSlice.map((data: IHireItem) => {
      return { id: data.id, count: data.quantity };
    });
    console.log({ ...data, hireitems: hireitems});
    await storeQuotation({ ...data, hireitems: hireitems })
      .then((response: any) => {
        toast.info('Quotation Request Sent');
        setFormKey(formKey === 0 ? 1 : 0);
        store.dispatch(clearCart());
        store.dispatch(clearForm());
        return response?.data?.data?.id;
      })
      .catch((error: any) => {
        if (error?.response?.data?.data) {
          var errors = Object.values(error?.response?.data?.data);
          errors.forEach((value: any) => {
            toast.error(value[0]);
          });
        } else {
          toast.error('Error saving item');
        }
      });

    setLoading(false);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Header
          title="Contact Us"
          subtitle="Get help organising delicious and efficient catering for your
        events today."
          backgroundImage={getBannerImageByName('contactpagebanner', banners)}
        />
        <Flex w={{ base: '100%', md: '80%' }} pt="50" mx="auto" flexWrap="wrap" flexDirection={{base:"row-reverse",sm:"row"}}>
          <Box w={{ base: '100%', md: '50%' }} px="4" py="2">
            <Heading fontFamily="Playfair Display" fontWeight="400" mb="6">
              Get in Touch to Get the Ball Rolling
            </Heading>
            <Text>
              Got an Event Planned and need help with planning cattering or
              quotations? Please shoot us an email at{' '}
              <a href="mailto: admin@cater4u.co.zw" style={{ color: 'blue' }}>
                admin@cater4u.co.zw
              </a>
            </Text>
            <Divider variant="solid" borderColor="gray.500" my="4" />
            <Text mt="4">
              <span style={{ fontWeight: 900 }}>Call:</span>{' '}
              <a href="tel: +263774161459">+263 774 161 459</a>{' '}
              <a href="tel: +263242320310">+263 242 320 310</a>
            </Text>
            <Text>
              <span style={{ fontWeight: 900 }}>Whatsapp:</span>{' '}
              <a href="https://wa.me/263774161459?text=I'm%20inquiring%20about%20your%20services">
                +263 774 161 459
              </a>
            </Text>
            <Text>
              <span style={{ fontWeight: 900 }}>Email:</span>{' '}
              <a href="mailto: admin@cater4u.co.zw">admin@cater4u.co.zw</a>
            </Text>
            <Divider variant="solid" borderColor="gray.500" my="4" />
            <Text >
              23 - 6th Avenue Mabelreign<br></br>
              Harare Zimbabwe
            </Text>
            <Box p="4" mt="4" backgroundColor="white" shadow="md">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d1741.9198086037109!2d30.99423687702598!3d-17.784452383020394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m0!4m3!3m2!1d-17.7835647!2d30.9949354!5e0!3m2!1sen!2szw!4v1633453617070!5m2!1sen!2szw"
                width="100%"
                height="450"
                style={{ border: '0' }}
                allowFullScreen={true}
                loading="lazy"
                title="googlemap"
              ></iframe>
            </Box>
          </Box>
          <Box w={{ base: '100%', md: '50%' }} px="4" py="2">
            <Card mb="20">
              <Heading fontFamily="playfair" fontWeight="400">
                Get A quote
              </Heading>
              <Divider variant="solid" borderColor="gray.500" my="4" />
              <Formik
                key={formKey}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={FormSchema}
                onSubmit={values => {
                  // console.log(values);
                  submit(values);
                }}
              >
                {({ isSubmitting, values }) => (
                  <Form>
                    <Flex wrap="wrap">
                      <Field name="name">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={form.errors.name && form.touched.name}
                            isRequired
                            mt="8"
                          >
                            <FormLabel>Name</FormLabel>
                            <Input
                              borderRadius="0"
                              borderColor="gray.300"
                              {...field}
                              type="text"
                              placeholder="full name"
                              _autofill={{
                                transition: 'all 5000s ease-in-out 0s',
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="phone">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            w={{ base: '100%', md: '50%' }}
                            pr="2"
                            isInvalid={form.errors.phone && form.touched.phone}
                            isRequired
                            mt="4"
                          >
                            <FormLabel>Phone</FormLabel>
                            <Input
                              borderRadius="0"
                              borderColor="gray.300"
                              {...field}
                              type="text"
                              placeholder="phone"
                              _autofill={{
                                transition: 'all 5000s ease-in-out 0s',
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors.phone}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="whatsapp">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            w={{ base: '100%', md: '50%' }}
                            pl="2"
                            isInvalid={
                              form.errors.whatsapp && form.touched.whatsapp
                            }
                            isRequired
                            mt="4"
                          >
                            <FormLabel>Whatsapp</FormLabel>
                            <Input
                              borderRadius="0"
                              borderColor="gray.300"
                              {...field}
                              type="text"
                              placeholder="whatsapp number"
                              _autofill={{
                                transition: 'all 5000s ease-in-out 0s',
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors.whatsapp}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="email">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={form.errors.phone && form.touched.phone}
                            isRequired
                            mt="4"
                          >
                            <FormLabel>Email</FormLabel>
                            <Input
                              borderRadius="0"
                              borderColor="gray.300"
                              {...field}
                              type="email"
                              placeholder="email"
                              _autofill={{
                                transition: 'all 5000s ease-in-out 0s',
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Text w="full" mt="6">
                        Tick Services you want
                      </Text>
                      <Box w="full">
                        <Field name="catering">
                          {({ field, form }: FieldProps<any>) => (
                            <Checkbox
                              {...field}
                              mt="6"
                              onChange={e => {
                                setCatering(!!e.target.checked);
                              }}
                            >
                              Catering
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="hireitems">
                          {({ field, form }: FieldProps<any>) => (
                            <Checkbox
                              {...field}
                              isChecked={hiring}
                              onChange={e => {
                                setHiring(!!e.target.checked);
                              }}
                              mt="6"
                              ml="6"
                            >
                              HireItems
                            </Checkbox>
                          )}
                        </Field>
                      </Box>
                      {catering && (
                        <Flex w="full" wrap="wrap">
                          <Field name="venue">
                            {({ field, form }: { field: any; form: any }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.phone && form.touched.phone
                                }
                                mt="4"
                              >
                                <FormLabel>Venue</FormLabel>
                                <Input
                                  borderRadius="0"
                                  borderColor="gray.300"
                                  {...field}
                                  type="text"
                                  placeholder="venue"
                                  _autofill={{
                                    transition: 'all 5000s ease-in-out 0s',
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.venue}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="attendees">
                            {({ field, form }: { field: any; form: any }) => (
                              <FormControl
                                pr="2"
                                w="50%"
                                isInvalid={
                                  form.errors.phone && form.touched.phone
                                }
                                mt="4"
                              >
                                <FormLabel>Number Attending</FormLabel>
                                <Input
                                  borderRadius="0"
                                  borderColor="gray.300"
                                  {...field}
                                  type="number"
                                  placeholder="attendees"
                                  _autofill={{
                                    transition: 'all 5000s ease-in-out 0s',
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.attendees}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="date">
                            {({ field, form }: { field: any; form: any }) => (
                              <FormControl
                                w="50%"
                                pl="2"
                                isInvalid={
                                  form.errors.phone && form.touched.phone
                                }
                                mt="4"
                              >
                                <FormLabel>Date</FormLabel>
                                <Input
                                  borderRadius="0"
                                  borderColor="gray.300"
                                  {...field}
                                  type="date"
                                  placeholder="date"
                                  _autofill={{
                                    transition: 'all 5000s ease-in-out 0s',
                                  }}
                                />
                                <FormErrorMessage>
                                  {form.errors.date}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="event_id">
                            {({ field, form }: { field: any; form: any }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.event_id && form.touched.event_id
                                }
                                mt="4"
                              >
                                <FormLabel>Event</FormLabel>
                                <Select
                                  borderRadius="0"
                                  borderColor="gray.300"
                                  placeholder="Select Event"
                                  // {...field}
                                  onChange={e => {
                                    form.setFieldValue('event_id', e.target.value)
                                    setMenus(
                                      data?.data?.data?.filter(
                                        (event: IEvent) => {
                                          return (
                                            event.id ===
                                            parseInt(e.target.value)
                                          );
                                        }
                                      )[0]?.menus
                                    );
                                  }}
                                  // {...field}
                                >
                                  {data?.data?.data.map(
                                    (event: IEvent, i: any) => {
                                      return (
                                        <option
                                          key={i}
                                          value={event.id}
                                          onClick={e => {
                                            //console.log('clicked')
                                            setMenus(event.menus);
                                          }}
                                        >
                                          {event.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Select>
                                <FormErrorMessage>
                                  {form.errors.event_id}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="menu_id">
                            {({ field, form }: { field: any; form: any }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.menu_id && form.touched.menu_id
                                }
                                mt="4"
                              >
                                <FormLabel>Menu</FormLabel>
                                <Select
                                  borderRadius="0"
                                  borderColor="gray.300"
                                  {...field}
                                  placeholder="Select Menu"
                                >
                                  {menus?.map((menu: IMenu, i: any) => {
                                    return (
                                      <option value={menu.id} key={i}>
                                        {menu.name}
                                      </option>
                                    );
                                  })}
                                </Select>
                                <FormErrorMessage>
                                  {form.errors.menu_id}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                      )}

                      {/* <Link to={RouteNames.hireitems}> */}
                      {hiring && (
                        <Box w="full">
                          <Box w="full">
                            <Field name="hiring">
                              {({ field, form }: { field: any; form: any }) => (
                                <Text
                                  onClick={e => {
                                    updateFormStore(form.values);
                                    history.push('/hire');
                                  }}
                                  mt="8"
                                  color="blue"
                                  _hover={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Click this link to add hire items to your
                                  quote.
                                </Text>
                              )}
                            </Field>
                          </Box>
                          {cart.HireItemsSlice.length > 0 && (
                            <Text mt="8" w="full">
                              Hire Items
                            </Text>
                          )}

                          {/* <pre>{JSON.stringify(cart, null, 1)}</pre> */}
                          <Box width="full">
                            {cart.HireItemsSlice.map(
                              (hireitem: IHireItem, i: any) => {
                                return (
                                  <HireItem
                                    hireitem={hireitem}
                                    type={'store'}
                                    key={i}
                                  />
                                );
                              }
                            )}
                          </Box>
                        </Box>
                      )}
                      <Field name="comments">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={
                              form.errors.comments && form.touched.comments
                            }
                            mt="8"
                          >
                            <FormLabel>Comments/Extra Requirements</FormLabel>
                            <Textarea
                              borderRadius="0"
                              rows="10"
                              placeholder="comments"
                              {...field}
                              type="comments"
                              _autofill={{
                                transition: 'all 5000s ease-in-out 0s',
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors.comments}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button
                        colorScheme="blue"
                        w="full"
                        borderRadius="0"
                        mt="6"
                        type="submit"
                        isLoading={loading}
                        loadingText="Submitting"
                      >
                        Request Quotation
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </Box>
        </Flex>
      </Box>
    </Page>
  );
};

export { ContactUs };
