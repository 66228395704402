import {
  Box,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  storeProduct,
  uploadProductImage,
} from '../../../../services/products.service';
import Card from '../../../../components/Card';
import { Dropzone } from '../../../../components/Dropzone';
import { getCategories } from '../../../../services/categories.service';

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  position: Yup.number(),
});

interface ICreateProductCard {
  menu_id: number | string;
}

const CreateProductCard = ({ menu_id }: ICreateProductCard) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [images, setImages] = useState();

  const [key, setKey] = useState(1);

  interface ICategory {
    name: string;
    type: string;
    id: number;
    in_stock: boolean;
  }
  function getImages(images: any) {
    setImages(images);
  }

  function formatFormdata(data: any) {
    const formData = new FormData();
    formData.append('image', data ? data[0] : null);
    return formData;
  }

  interface MyFormValues {
    name: string;
    description: string;
    price: string;
    category_id: number | string;
    sku: string;
    in_stock: boolean;
  }
  const initialValues: MyFormValues = {
    name: '',
    description: '',
    price: '',
    category_id: '',
    sku: '',
    in_stock: true,
  };

  async function submit(data: any) {
    //console.log(data);
    setLoading(true);
    var image = formatFormdata(images);
    // if (!images || typeof images[0] === 'undefined') {
    // if (!images) {
    //   toast.error('image required');
    //   setLoading(false);
    //   return;
    // }

    var id = await storeProduct({ ...data })
      .then((response: any) => {
        setKey(key ? 0 : 1);
        toast.info('Product Added');
        return response?.data?.data?.id;
      })
      .catch((error: any) => {
        if (error?.response?.data?.data) {
          var errors = Object.values(error?.response?.data?.data);
          errors.forEach((value: any) => {
            toast.error(value[0]);
          });
        } else {
          toast.error('Error saving item');
        }
      });

    if (id && images) {
      await uploadProductImage(id, image)
        .then((response: any) => {
          toast.info('Image uploaded');
        })
        .catch((error: any) => {
          //console.log(error);
          toast.error('error uploading image');
        });

      setKey(key ? 0 : 1);
    }

    setLoading(false);
  }

  useEffect(() => {
    getCategories()
      .then((response: any) => {
        setCategories(response?.data?.data);
      })
      .catch((error: any) => {
        toast.error('failed loading categories');
      });
  }, []);
  return (
    <Card
      width={{ base: '100%', md: '50%' }}
      mt="8"
      mb="4"
      px={{ base: '4', md: '4' }}
      borderRadius="md"
      shadow="md"
      py="4"
      key={key}
    >
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={values => {
            submit(values);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <Field name="name">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Name</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="name"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="sku">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.sku && form.touched.sku}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Sku (Product Code)</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="sku"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>{form.errors.sku}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                    mt="8"
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      placeholder="description"
                      {...field}
                      type="description"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="price">
              {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={
                      form.errors.price && form.touched.price
                    }
                    mt="8"
                  >
                    <FormLabel>Price</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="price"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.price}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="category_id">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={
                      form.errors.category_id && form.touched.category_id
                    }
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Category</FormLabel>
                    <Select {...field} placeholder="Select Category" isRequired>
                      {categories?.map(category => {
                        return (
                          <option value={category.id} key={category.id}>
                            {category.name} - {category.type}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      {form.errors.category_id}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="in_stock">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.in_stock && form.touched.in_stock}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>In stock</FormLabel>
                    <Select {...field} placeholder="In stock?" isRequired>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Select>
                    <FormErrorMessage>{form.errors.in_stock}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Text mt="8">Image</Text>
              <Dropzone getImages={getImages} mt="2"></Dropzone>

              <Button
                colorScheme="blue"
                // w="full"
                mt="6"
                type="submit"
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Card>
  );
};

export default CreateProductCard;
