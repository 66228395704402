// the sidebar content

import {
  Box,
  Flex,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import Links from './Links';
import NavItem from './NavItem';
import logo from '../../assets/images/cater4u.webp';
import { Link } from 'react-router-dom';

export interface ComponentProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...other }: ComponentProps) => {
  return (
    <Box
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      display={{ base: 'none', md: 'block' }}
      {...other}
    >
      <Flex
        ml={{ base: 0, md: 0 }}
        px={{ base: 4, md: 8 }}
        height="20"
        alignItems="center"
        justifyContent={{ base: 'space-between', md: 'space-between' }}
      >
        <Link to="/">
          <Image src={logo} width={{ base: '70%', md: '100%' }} />
        </Link>

        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
          variant="outline"
          aria-label="open menu"
          icon={<FiX />}
        />
      </Flex>
      {Links.map(link => (
        <NavItem key={link.name} icon={link.icon} url={link.link}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

export default SidebarContent;
