import { Box, Text } from "@chakra-ui/react";

export const MinimalFooter = () => {
  return (
    <Box bg="gray.800" color="white">
      <Box bg="gray.900">
          <Text textAlign="center" fontSize="sm" py="4">Copyright Cater 4 U 2021</Text>
      </Box>
    </Box>
  );
};