import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import CreateCategoryCard from './CreateCategoryCard';

export const CreateCategoryPage = () => {
  return (
    <Page title="add category">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            Add Category
          </Text>
          <Spacer />
          <Link to={`${RouteNames.admin.categories}`}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <CreateCategoryCard />
      </Box>
    </Page>
  );
};
