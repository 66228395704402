import { Box,  Link, Badge } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {  getOrders } from '../../../../services/orders.service';
import { TableCard } from '../../../../components/TableCard';
import { RouteNames } from '../../../../common/RouteNames';
const edit_link = RouteNames.admin.orders + '/';

export const ListOrdersCard = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: ({ row: { original } }: { row: any }) => {
          return `$${original.total}`;
        },
      },
      {
        Header: 'Fulfilled',
        accessor: 'fulfilled',
        Cell: ({ row: { original } }: { row: any }) => {
          return original.fulfilled ? (
            <Badge colorScheme="green">Yes</Badge>
          ) : (
            <Badge colorScheme="red">No</Badge>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ row: { original } }: { row: any }) => {
          return moment(original?.created_at).format('MM/DD/YYYY');
        },
      },
      {
        disableSortBy: true,
        Header: 'Actions',
        accessor: 'actions',
        Cell: (tableProps: any) => (
          <>
            <Link
              as={RouterLink}
              to={edit_link + '' + tableProps.row.original.id}
              color="blue.500"
            >
              View
            </Link>
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getOrders()
      .then((response: any) => {
        //console.log(response);
        setData(response?.data?.data);
      })
      .catch((error: any) => {
        //console.log(error?.response);
      });
  }, []);

  return (
    <Box>
      <TableCard
        data={data}
        columns={columns}
        edit_link={edit_link}
      ></TableCard>
    </Box>
  );
};
