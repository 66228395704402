import { axiosInstance } from '../common/API';
const BASE_PATH = 'categories';
const types = ['shop','catertogo']

interface DataType {
  [key: string]: string | boolean | number;
}

function getCategory(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getCategories(type = '') {
  var path = types.includes(type)? `${BASE_PATH}?type=${type}`: `${BASE_PATH}`
  return axiosInstance.get(path);
}

function storeCategory(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateCategory(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteCategory(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function uploadCategoryImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getCategory,
  getCategories,
  storeCategory,
  updateCategory,
  deleteCategory,
  uploadCategoryImage,
};
