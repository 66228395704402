import Icon from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import { Box, Flex, Spacer, Text } from '@chakra-ui/layout';
import * as React from 'react';
import { FiX } from 'react-icons/fi';
import { getImageURL } from '../../../common/ImageHelpers';
import { IProduct } from '../../../interfaces/ProductInterfaces';
import store from "../../../common/store"
import { removeItem } from '../../../slices/ShopProductsSlice';
import { removeItem as CaterRemoveItem } from '../../../slices/CaterToGoProductsSlice';

export const CartItem = ({ product,type }: { product: IProduct,type:string }) => {
    
    function removeFromCart(product:IProduct){
        if(type==="store"){
            store.dispatch(removeItem({ product: product}));
        }
        if(type==="catertogo"){
            store.dispatch(CaterRemoveItem({ product: product}));
        }
    }

  return (
    <Flex borderBottom="solid 1px" borderColor="gray.200" py={8}>
      <Image
        src={getImageURL(product.image)}
        borderRadius="full"
        boxSize={{base:"40px",md:"100px"}}
      />
      <Box my="auto" mx={{base:"2",md:"10"}}>
        <Text fontFamily="playfair" fontWeight="600" fontSize={{base:"md",md:"2xl"}}>
          {product.name}
        </Text>
        <Text fontSize={{base:"xs",md:"sm"}}>{product.description}</Text>
      </Box>
      <Spacer />
      <Box my="auto">
        <Text whiteSpace="nowrap" fontSize={{base:"sm",md:"md"}}>
          {product.quantity} x ${product.price}
        </Text>
      </Box>
      <Spacer />
      <Box my="auto" mx="4" fontSize={{base:"sm",md:"md"}}>
        <Text>${product.price * product.quantity}</Text>
      </Box>
      <Box my="auto">
        <Text onClick={(e)=>{removeFromCart(product)}} color="red" _hover={{
            cursor:"pointer"
        }}>
          <Icon as={FiX} />
        </Text>
      </Box>
    </Flex>
  );
};
