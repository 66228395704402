import { axiosInstance } from '../common/API';
const BASE_PATH = 'orders';

interface DataType {
  [key: string]: string | boolean | number;
}

function getOrder(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getOrderCourses(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}/courses`);
}

function getOrders() {
  return axiosInstance.get(`${BASE_PATH}`);
}

function storeOrder(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateOrder(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteOrder(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function toggleFulfilled(id: string) {
  return axiosInstance.post(`${BASE_PATH}/${id}/togglefulfilled`);
}

function uploadOrderImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getOrder,
  getOrders,
  storeOrder,
  updateOrder,
  toggleFulfilled,
  getOrderCourses,
  deleteOrder,
  uploadOrderImage,
};
