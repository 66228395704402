import { configureStore } from '@reduxjs/toolkit';
import HireItemsSlice from '../slices/HireItemsSlice';
import ShopProductsSlice from '../slices/ShopProductsSlice';
import CaterToGoProductsSlice from '../slices/CaterToGoProductsSlice';
import ContactFormSlice from '../slices/ContactFormSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

const reducers = combineReducers({
  HireItemsSlice,
  ShopProductsSlice,
  CaterToGoProductsSlice,
  ContactFormSlice
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});
export type RootState = ReturnType<typeof store.getState>
export default store;
