function store(key: string, value: string, storage_type: string) {
  switch (storage_type) {
    case 'session':
      sessionStorage.setItem(key, value);
      break;
    case 'local':
      localStorage.setItem(key, value);
      break;
    default:
      throw new Error(`Invalid storage type ${storage_type}`);
  }
}

function get(key: string, storage_type: string) {
  switch (storage_type) {
    case 'local':
      return localStorage.getItem(key);
    default:
      return sessionStorage.getItem(key);
  }
}

function remove({key, storage_type}: { key: string; storage_type?: string }) {
  switch (storage_type) {
    case 'local':
      localStorage.removeItem(key);
      break;
    case 'session':
      sessionStorage.removeItem(key);
      break;
    default:
      sessionStorage.removeItem(key);
      localStorage.removeItem(key);
  }
}

export { store, get, remove };
