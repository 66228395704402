/**
 * Main navigation
 */
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Avatar,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import * as React from 'react';
import { FiBell, FiChevronDown, FiGrid } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { logoutUser } from '../../services/auth.service';
import user_icon from '../../assets/images/user_icon.png';
import logo from '../../assets/images/cater4u.webp';
import { Link } from 'react-router-dom';

interface ComponentProps {
  onOpen: () => void;
  user: any;
}

const MainNav = ({ onOpen, user, ...rest }: ComponentProps) => {
  const history = useHistory();

  function logout() {
    logoutUser();
    history.push('/auth/login');
  }
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <Link to="/">
        <Image src={logo} display={{ base: 'block', md: 'none' }} w="100%" />
      </Link>

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar size={'sm'} src={user_icon} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              {/* <MenuItem>Profile</MenuItem>
                  <MenuItem>Settings</MenuItem>
                  <MenuItem>Billing</MenuItem> */}
              <MenuDivider />
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          style={{ marginLeft: '16px' }}
          onClick={onOpen}
          // variant="outline"
          aria-label="open menu"
          icon={<FiGrid />}
        />
      </HStack>
    </Flex>
  );
};

export default MainNav;
