import * as React from 'react';
import { ShopCard } from '../../../components/cards/ShopCard';
import { Page } from '../../../components/Page';

export const CaterToGo = () => {
  return (
    <Page title="Amazing Catering in Harare">
      <ShopCard
        filterName="catertogo"
        title="Cater to Go"
        subtitle="Experience our flavours in the comfort of your home"
      />
    </Page>
  );
};
