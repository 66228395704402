// round figures to decimal places 
const roundToDecimalPlaces = (num: any, places: number) => {
  return (Math.round(num * 100) / 100).toFixed(places);
};

function pad(num:string|number, size:number) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export { roundToDecimalPlaces,pad};