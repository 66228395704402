import * as React from "react"
import { Route, Switch } from "react-router";
import { LoginPage } from "../pages/auth/LoginPage";
import { RegisterPage } from "../pages/auth/RegisterPage";

export const AuthRoutes = ()=>{
    return (
        <Switch>
            <Route path="/auth/login" exact component={LoginPage}/>
            <Route path="/auth/register" exact component={RegisterPage}/>
        </Switch>
    );
}