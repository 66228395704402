import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList } from 'react-icons/fi';
import { Link,useParams } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import CreateCourseCard from './CreateCourseCard';

export const CreateCoursePage = () => {
  let { menu_id }: { menu_id: any } = useParams();
  return (
    <Page title="add course">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            Add Course 
          </Text>
          <Spacer />
          <Link to={`${RouteNames.admin.menus}/${menu_id}`}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <CreateCourseCard menu_id={menu_id} />
      </Box>
    </Page>
  );
};
