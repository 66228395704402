import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import EditBannerCard from './EditBannerCard';

export const EditBannerPage = () => {
  let { id }: { id: any } = useParams();
  return (
    <Page title="edit banner">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            Edit Banner
          </Text>
          <Spacer />
          <Link to={`${RouteNames.admin.banners}`}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <EditBannerCard banner_id={id} />
      </Box>
    </Page>
  );
};
