import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/layout';
import * as React from 'react';
import { getBannerImageByName } from '../common/ImageHelpers';
import { BannerContext } from '../layouts/MainLayout';

const LovinglyMade = () => {
  const banners = React.useContext(BannerContext);
  return (
    <Box
      mt="20"
      style={{
        background: `url(${getBannerImageByName('lovinglymade', banners)})`,
        backgroundSize: "cover",
        backgroundRepeat:"no-repeat"
      }}
      backgroundSize="cover"
      bgRepeat="no-repeat"
    >
      <Flex opacity=".8">
        <Spacer></Spacer>
        <Box
          w={{ base: '100%', md: '50%' }}
          my="10"
          bg="white"
          color="gray.900"
          px="10"
          py="10"
          fontSize={{base:"xs",md:"md"}}
        >
          <Heading
            fontFamily="Playfair Display"
            fontWeight="200"
            fontSize={{base:"2xl",md:"5xl"}}
          >
            Lovingly Made <br /> Perfectly Delivered
          </Heading>
          <Text mt="6">
            Cater4u was born out of a passionate love for food - the
            ingredients; the aroma and flavours; the flair and creativity of
            presentation and the absolute joy and warmth that food brings to any
            gathering.
          </Text>
          <Text mt="6">
            At Cater4u, we are so passionate about our food that we hold
            ourselves to exceptionally high standards that focus on giving you
            individualised flawless service at every touch point - we CATER4U!
          </Text>
          <Text mt="6">
            For us, good food evokes warm feelings of family, love, joy and
            celebration, and out of this love for creating joyful memories and
            lasting impressions, we have an unwavering commitment to
            personalised and excellent customer service.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default LovinglyMade;
