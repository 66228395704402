import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { Page } from '../../../../components/Page';
import { ListOrdersCard } from './ListOrdersCard';

const ListOrdersPage = () => {
  return (
    <Page title="list orders">
      <Box>
      <Flex>
        <Text fontSize="2xl" fontWeight="bold">
          Orders
        </Text>
        <Spacer />
      </Flex>
      <ListOrdersCard />
      </Box>
    </Page>
  );
};

export default ListOrdersPage;
