import { axiosInstance } from "../common/API";
const BASE_PATH = 'banners';

interface DataType {
  [key: string]: string | boolean | number;
}

function getBanner(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getBanners() {
  return axiosInstance.get(`${BASE_PATH}`);
}

function getBannerMenus(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}/menus`);
}

function getBannerBySlug(slug: string){
  return axiosInstance.get(`${BASE_PATH}/slug/${slug}/menus`)
}

function storeBanner(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateBanner(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteBanner(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function uploadBannerImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getBanner,
  getBannerMenus,
  getBannerBySlug,
  getBanners,
  storeBanner,
  updateBanner,
  deleteBanner,
  uploadBannerImage,
};
