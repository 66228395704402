import { axiosInstance } from '../common/API';
const BASE_PATH = 'quotations';

interface DataType {
  [key: string]: string | boolean | number;
}

function storeQuotation(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

export {
  storeQuotation,
};
