import { Box } from '@chakra-ui/layout';
import { BoxProps } from '@chakra-ui/react';
import * as React from 'react';

interface CardProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
}

const Card = ({ children, ...rest }: CardProps) => {
  return (
    <Box borderRadius="md" shadow="md" p="4" bg="white" {...rest}>
      {children}
    </Box>
  );
};

export default Card;
export type {CardProps}
