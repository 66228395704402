import Icon from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import { Box, Flex, Spacer, Text } from '@chakra-ui/layout';
import * as React from 'react';
import { FiX } from 'react-icons/fi';
import { getImageURL } from '../../../common/ImageHelpers';
import { IHireItem } from '../../../interfaces/HireItemsInterface';
import store from '../../../common/store';
import { removeItem } from '../../../slices/HireItemsSlice';

export const HireItem = ({
  hireitem,
  type,
}: {
  hireitem: IHireItem;
  type: string;
}) => {
  function removeFromCart(hireitem: IHireItem) {
    store.dispatch(removeItem({ hireitem: hireitem }));
  }

  return (
    <Flex borderBottom="solid 1px" borderColor="gray.200" py={4}>
      <Image
        src={getImageURL(hireitem.image)}
        borderRadius="full"
        boxSize="50px"
      />
      <Box my="auto" mx="10">
        <Text fontFamily="playfair" fontWeight="600" fontSize="md">
          {hireitem.name}
        </Text>
        <Text fontSize="xs">{hireitem.description}</Text>
      </Box>
      <Spacer />
      <Box my="auto">
        <Text>
          {hireitem.quantity} x ${hireitem.price}
        </Text>
      </Box>
      <Spacer />
      <Box my="auto">
        <Text>${hireitem.price * hireitem.quantity}</Text>
      </Box>
      <Spacer />
      <Box my="auto">
        <Text
          onClick={e => {
            removeFromCart(hireitem);
          }}
          _hover={{
            cursor: 'pointer',
          }}
        >
          <Icon as={FiX} />
        </Text>
      </Box>
    </Flex>
  );
};
