import { createSlice } from '@reduxjs/toolkit';
import { IContactForm } from '../interfaces/ContactDetailsTypes';

const initialState: IContactForm = {
  name: '',
  phone: '',
  whatsapp: '',
  email: '',
  venue: '',
  numberattending: 0,
  date: '',
  event_id: '',
  menu_id: '',
  comments:''
};

export const ContactFormSlice = createSlice({
  name: 'contactform',
  initialState,
  reducers: {
    updateForm: (
      state,
      action: {
        payload: { data: IContactForm };
        type: string;
      }
    ) => {
        state=action.payload.data
      

      return state;
    },
    clearForm: state => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateForm, clearForm } = ContactFormSlice.actions;

export default ContactFormSlice.reducer;
