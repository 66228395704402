import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Page } from '../../../../components/Page';
import Menu from '../Menu';
import { useEffect, useState } from 'react';
import { getEventBySlug } from '../../../../services/events.service';
import { toast } from 'react-toastify';
import { getImageURL } from '../../../../common/ImageHelpers';
import LovinglyMade from '../../../../components/LovinglyMade';
import { EventHeader } from '../../../../components/EventHeader';

interface IMenu {
  name: string;
  courses: any;
}

interface IEvent {
  name: string;
  subtitle: string;
  image: '';
}

export const EventMenus = () => {
  const [menus, setMenus] = useState([]);
  const [event, setEvent] = useState<IEvent>({
    name: '',
    subtitle: '',
    image: '',
  });
  let { menu_slug }: { menu_slug: any } = useParams();

  useEffect(() => {
    getEventBySlug(menu_slug)
      .then((response: any) => {
        //console.log(response?.data?.data);
        setMenus(response?.data?.data?.menus);
        setEvent(response?.data?.data);
      })
      .catch(error => {
        toast.error('Loading Error');
      });
  }, [menu_slug]);
  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <EventHeader title={event.name} subtitle={event.subtitle} backgroundImage={getImageURL(event.image)}/>
        <Text
          width="full"
          textAlign="center"
          fontFamily="playfair display"
          lineHeight={1.2}
          my="8"
          mt="12"
          fontSize={useBreakpointValue({ base: '2xl', md: '5xl' })}
          fontWeight="100"
        >
          Browse through our Menus
        </Text>
        <Tabs
          mt="8"
          align="center"
          color="gray.400"
          mx={{ base: '2', md: '8' }}
          colorScheme="orange"
        >
          <TabList borderColor="gray.200" mb="10" fontFamily="playfair display">
            {menus.map((menu: IMenu, i) => {
              return (
                <Tab key={i} fontSize={{base:"xs",md:"lg"}}>
                  {menu.name}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels>
            {menus.map((menu: IMenu, i) => {
              return (
                <TabPanel key={i} style={{ background: '#3C3D3F' }}>
                  <Menu name={menu.name} courses={menu.courses} />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
        <LovinglyMade/>  </Box>
    </Page>
  );
};
