import * as React from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';

const FAB = ({ link }: { link: string }) => {
  return (
    <Link to={link}>
      <Box
        className="fab-container"
        bottom={{ base: 4, md: 12 }}
        right={{ base: 4, md: 12 }}
        backgroundColor="#CA4545"
        paddingY={{ base: 2, md: 3 }}
        paddingX={{ base: 5, md: 6 }}
        style={{
          color:'white',
          display: 'flex',
          alignItems: 'center',
          border: 'solid 2px',
          borderColor: '#CA4545',
          transition: 'all .5'
        }}
        role="group"
        borderRadius="10"
        _hover={{
          transition: 'all .5s',
          borderColor: '#CA4545 !important',
          backgroundColor: 'white',
          color:'#CA4545 !important'
        }}
      >

        <Text
        >
          Next Step
        </Text>
        <Icon
          as={FiArrowRight}
          style={{ display: 'inline'}}
        />
      </Box>
    </Link>
  );
};

export default FAB;
