import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  Link,
  useDisclosure,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { FiX, FiMenu } from 'react-icons/fi';
import { Link as Rlink } from 'react-router-dom';
import { FaPhoneSquareAlt, FaWhatsapp } from 'react-icons/fa';
import logo from '../../assets/images/cater4u.webp';
import { RiShoppingCartFill } from 'react-icons/ri';
import { MainRoutes } from '../../routes/MainRoutes';
import { MainFooter } from '../../components/footers/MainFooter';
import NavLink from './Navlink';
import MobileNav from './MobileNav';
import { Link as RouterLink } from 'react-router-dom';
import Links from './DesktopLinks';
import { createContext } from 'react';
import { getBanners } from '../../services/banners.service';
import { IBanner } from '../../interfaces/BannerTypes';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import Loader from '../../components/Loader';

export const BannerContext = createContext<Array<IBanner>>([]);

export const MainLayout = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const cart = useSelector((state: RootStateOrAny) => state);
  const { isLoading, data } = useQuery('banners', getBanners);
  const cartItemsCount =
    cart.ShopProductsSlice.length + cart.CaterToGoProductsSlice.length;
  if (isLoading) {
    return <Loader />;
  }

  return (
    <BannerContext.Provider value={data?.data}>
      <Box bg="gray.50" color="gray.900">
        <Box>
          {/* pre nav bar */}
          <Flex px="4" bg="gray.900">
            <Spacer display={{ base: 'none', md: 'flex' }} />
            <Box
              my="2"
              fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
              color="white"
              mx="auto"
            >
              <HStack>
                <a
                  href="https://wa.me/263774161459"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    style={{
                      marginBottom: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    role="group"
                  >
                    <FaWhatsapp
                      color={'green'}
                      style={{ display: 'inline', fontSize: 16 }}
                    />{' '}
                    <Text
                      style={{ display: 'inline-block' }}
                      _groupHover={{ textDecoration: 'underline' }}
                    >
                      +263 774 161 459
                    </Text>
                  </Box>
                </a>
                <Box style={{ marginBottom: 4 }}>|</Box>

                <a href="tel:+263242320310">
                  <Box
                    style={{
                      marginBottom: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    role="group"
                  >
                    <FaPhoneSquareAlt
                      color={'green'}
                      style={{ display: 'inline', fontSize: 16 }}
                    />
                    <Text
                      style={{ display: 'inline-block' }}
                      _groupHover={{
                        textDecoration: 'underline',
                      }}
                    >
                      +236 242 320 310
                    </Text>
                  </Box>
                </a>
              </HStack>
            </Box>
          </Flex>
          {/* navbar */}
          <Box>
            <Flex
              bg="rgba(255,255,255,.9)"
              py="2"
              px={{ base: '6', md: '16' }}
              alignItems={'center'}
              justifyContent={'space-between'}
              minH={'60px'}
            >
              <Flex
                flex={{ base: 1, md: 'auto' }}
                ml={{ base: -2 }}
                display={{ base: 'flex', md: 'none' }}
              >
                <IconButton
                  borderRadius="0"
                  onClick={onToggle}
                  icon={
                    isOpen ? (
                      <Icon w={5} h={5} as={FiX} />
                    ) : (
                      <Icon w={5} h={5} as={FiMenu} />
                    )
                  }
                  variant={'ghost'}
                  colorScheme={'green'}
                  aria-label={'Toggle Navigation'}
                />
              </Flex>
              <Link
                to="/"
                as={RouterLink}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <Image src={logo} alt="cater 4 U logo" width="200px" />
              </Link>

              <Spacer />
              <Box textColor="gray.900" py="auto">
                <HStack
                  as={'nav'}
                  fontFamily="lexend"
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                >
                  {Links.map((link, i) => (
                    <NavLink url={link.url} key={i}>
                      {link.name}
                    </NavLink>
                  ))}
                  <Link
                    px={4}
                    py={2}
                    color="white"
                    position="relative"
                    transition="all .3s"
                    border="2px"
                    bg="red.600"
                    _hover={{
                      color: 'red.600',
                      bg: '#fff',
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                    to="/cart"
                    as={RouterLink}
                  >
                    <span style={{ marginRight: '5px' }}>My Cart</span>{' '}
                    <Icon boxSize={4} as={RiShoppingCartFill}></Icon>
                    {cartItemsCount > 0 && (
                      <Box
                        position="absolute"
                        top="-4"
                        right="-4"
                        lineHeight="30px"
                        borderRadius="50%"
                        width="30px"
                        height="30px"
                        textAlign="center"
                        fontSize="15px"
                        border="solid 1px white"
                        color="white"
                        background="red.600"
                      >
                        {cartItemsCount}
                      </Box>
                    )}
                  </Link>
                </HStack>
              </Box>
            </Flex>
            <Collapse
              in={isOpen}
              animateOpacity
              style={{ position: 'absolute', width: '100vw', zIndex: 10000 }}
            >
              <MobileNav onClose={onClose} />
            </Collapse>
          </Box>
        </Box>
        {/* content is here */}
        <Box>
          <MainRoutes />
        </Box>
        {/* footer */}
        <MainFooter></MainFooter>
      </Box>
    </BannerContext.Provider>
  );
};
