import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import Card from './Card';
import { Page } from '../../../components/Page';
import { useContext, useMemo } from 'react';
import { getEvents } from '../../../services/events.service';
import { RouteNames } from '../../../common/RouteNames';
import { BannerContext } from '../../../layouts/MainLayout';
import { threeXtwo } from '../../../common/Assets';
import {
  getBannerImageByName,
  getImageURL,
} from '../../../common/ImageHelpers';
import Loader from '../../../components/Loader';
import { useQuery } from 'react-query';
import LovinglyMade from '../../../components/LovinglyMade';

interface Event {
  name: string;
  image: string;
  slug: string;
  id: number;
}

export const Home = () => {
  const banners = useContext(BannerContext);
  const { isLoading, data } = useQuery('events', getEvents);

  const menus = useMemo(
    () => [
      {
        name: 'Our Shop',
        slug: 'shop',
        image: getBannerImageByName('shopcardimage', banners),
        cta: 'View More',
      },
      {
        name: 'Hire It',
        slug: 'hire',
        image: getBannerImageByName('hirecardimage', banners),
        cta: 'View More',
      },
      {
        name: 'Cater to Go',
        slug: 'cater-to-go',
        image: getBannerImageByName('catertogocardimage', banners),
        cta: 'View More'
      }
    ],
    [banners]
  );

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Flex
          w={'full'}
          h={{ base: '80vh', md: '100vh' }}
          minH={{ base: '90px', md: '400px' }}
          backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${getBannerImageByName(
            'homepagebanner',
            banners
          )})`}
          backgroundSize={'cover'}
          backgroundRepeat="no-repeat"
          backgroundAttachment="fixed"
          backgroundPosition={'center center'}
        >
          <VStack
            w={'full'}
            justify={'center'}
            px={{ base: 4, md: 8 }}
            bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
          >
            <Stack
              maxW={'4xl'}
              mt="10"
              align={'flex-start'}
              spacing={6}
              textAlign="center"
            >
              <Heading
                as="h1"
                color={'white'}
                fontWeight={200}
                lineHeight={1.2}
                textStyle="heading"
                fontFamily="Playfair Display"
                fontSize={{ base: '3xl', md: '6xl' }}
              >
                AMAZING CATERING FOR EVERY OCCASION
              </Heading>
              <Text
                width="full"
                textAlign="center"
                color={'white'}
                fontFamily="Playfair Display"
                lineHeight={1.2}
                fontSize={{ base: '2xl', md: '3xl' }}
              >
                Weddings | Corporate Events | Conferences | Social Events
              </Text>
            </Stack>
          </VStack>
        </Flex>
        <Box w="" mt="-5em" mx={{ base: '1', md: '1' }}>
          <Wrap w="" justify="center">
            {data?.data?.data.map((event: Event, i: any) => {
              return (
                <WrapItem
                  key={i}
                  w={{ base: '100%', md: '30%' }}
                  style={{ display: 'flex', justifyContent: 'center' }}
                  px="2"
                >
                  <Card
                    name={event.name}
                    image={getImageURL(event.image)}
                    url={RouteNames.events + '/' + event.slug}
                    cta={'View Menus'}
                  ></Card>
                </WrapItem>
              );
            })}
            {menus.map((item, index) => {
              return (
                <WrapItem
                  key={index}
                  w={{ base: '100%', md: '30%' }}
                  px="2"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Card
                    name={item.name}
                    image={item.image}
                    url={item.slug}
                    cta={item.cta}
                  ></Card>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>

        <LovinglyMade />

        <Heading
          as="h2"
          width="full"
          textAlign="center"
          fontFamily="playfair"
          lineHeight={1.2}
          fontWeight="100"
          my="20"
          fontSize={{ base: '2xl', md: '5xl' }}
        >
          It's all about the food
        </Heading>

        <Box w="">
          <Wrap w="100%" mx="auto" style={{ margin: '0px' }} overflow="hidden">
            {banners
              .filter(banner => {
                return banner.name === 'homepagegallery';
              })
              .map((item, index) => {
                return (
                  <WrapItem
                    key={index}
                    px={{ base: 2, md: 0 }}
                    py={{ base: 2, md: 0 }}
                    w={{ base: '50%', md: '25%' }}
                    style={{ margin: 0 }}
                  >
                    <Image
                      src={getImageURL(item.image)}
                      fallbackSrc={threeXtwo}
                    ></Image>
                  </WrapItem>
                );
              })}
          </Wrap>
        </Box>
      </Box>
    </Page>
  );
};
