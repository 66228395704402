import { Box, Flex, Heading, Text, Button, Divider } from '@chakra-ui/react';

import { Page } from '../../../components/Page';
import { useContext } from 'react';
import { Header } from '../../../components/Header';
import { getEvents } from '../../../services/events.service';
import { Link } from 'react-router-dom';
import { FadeCard } from '../../../components/cards/FadeCard';
import { IEvent } from '../../../interfaces/EventInterfaces';
import { RouteNames } from '../../../common/RouteNames';
import { BannerContext } from '../../../layouts/MainLayout';
import { getImageURL } from '../../../common/ImageHelpers';
import { getBannerImageByName } from '../../../common/ImageHelpers';
import Loader from '../../../components/Loader';
import { useQuery } from 'react-query';

export const Events = () => {
  const banners = useContext(BannerContext);
  const { isLoading, data } = useQuery('events', getEvents);

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <Header
          title="Events"
          subtitle="Find the perfect Menus for your Event"
          backgroundImage={getBannerImageByName('eventspagebanner', banners)}
        />
        <Flex my="20" width={{ base: '100%', md: '90%' }} mx="auto" wrap="wrap">
          {data?.data?.data.map((event: IEvent, i: any) => {
            //get max lenth of menus
            const lengths = data?.data?.data.map((item: IEvent) => {
              return item.menus.length;
            });
            while (lengths.length > 1) {
              if (lengths[0] > lengths[1]) {
                lengths.splice(1, 1);
              } else {
                lengths.splice(0, 1);
              }
            }
            const maxLength = lengths[0];
            return (
              <Box w={{ base: '100%', md: '30%' }} p="2" mx={'auto'} key={i}>
                <Box p="4" backgroundColor="gray.200">
                  <FadeCard image={getImageURL(event.image)}>
                    <Box px="4">
                      <Heading
                        mt="-10"
                        fontFamily="playfair"
                        fontSize="3xl"
                        px="4"
                        fontWeight="500"
                        color="gray.900"
                        textAlign="center"
                      >
                        {event.name}
                      </Heading>
                      <Box>
                        <Divider
                          variant="solid"
                          borderColor="gray.500"
                          my="4"
                          w="100px"
                          mx="auto"
                        />
                      </Box>
                      <Box
                        color=""
                        textAlign="center"
                        fontFamily="playfair"
                        my="10"
                      >
                        {event.menus.map((menu, i) => {
                          return (
                            <Text my="4" key={i}>
                              {menu.name}
                            </Text>
                          );
                        })}
                        {
                          new Array(maxLength-event.menus.length).fill([]).map((_,i)=>{
                            return <Text my="4" key={i} color="white">{"."}</Text>
                          })
                        }
                      </Box>
                      <Link to={RouteNames.events + '/' + event.slug}>
                        <Button
                          w="full"
                          borderRadius="0"
                          colorScheme="red"
                          mb="5"
                          size="lg"
                        >
                          View Menus In Detail
                        </Button>
                      </Link>
                    </Box>
                  </FadeCard>
                </Box>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Page>
  );
};
