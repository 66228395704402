import {
  Box,
  Text,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getOrder, toggleFulfilled } from '../../../../services/orders.service';
import Card from '../../../../components/Card';
import { BoxProps } from '@chakra-ui/react';

interface IOrder {
  name: string;
  phone: string;
  total: string;
  fulfilled: boolean
  products: Array<any>;
}

interface ComponentProps extends BoxProps {
  id: string;
}

const ViewOrderCard = ({ id, ...other }: ComponentProps) => {
  const [order, setOrder] = useState<IOrder>({
    name: '',
    phone: '',
    total: '',
    fulfilled: false,
    products: [],
  });

  function toggle(){
    toggleFulfilled(id).then((response)=>{
      setOrder(response?.data?.data);
    }).catch(()=>toast.error('toggling order status failed'))
  }
  //   setup form
  useEffect(() => {
    getOrder(id)
      .then((response: any) => {
        setOrder(response?.data?.data);
      })
      .catch((error: any) => {
        toast.error('error loading order');
      });
  }, [id]);

  return (
    <Card
      width="100%"
      mb="4"
      px={{ base: '4', md: '4' }}
      borderRadius="md"
      shadow="md"
      py="4"
      {...other}
    >
      <Box>
        <Box mb={6}>
          <Text fontSize="xs" fontWeight="700">
            Client Name
          </Text>
          <Text>{order.name}</Text>
        </Box>

        <Box mb={6}>
          <Text fontSize="xs" fontWeight="700">
            Phone Number
          </Text>
          <Text>{order.phone}</Text>
        </Box>

        <Box mb={6}>
          <Text fontSize="xs" fontWeight="700">
            Order Total
          </Text>
          <Text>${order.total}</Text>
        </Box>

        <Box mb={6}>
          <Text fontSize="xs" fontWeight="700">
            Fulfilled
          </Text>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              {order.fulfilled?'Yes':'No'}
            </FormLabel>
            <Switch id="email-alerts" onChange={(e)=>{toggle()}}/>
          </FormControl>
        </Box>

        <Box mb={6}>
          <Text fontSize="xs" fontWeight="700">
            Products
          </Text>
          <Table
            variant="simple"
            backgroundColor="green.50"
            shadow="md"
            borderRadius={4}
          >
            <Thead>
              <Tr>
                <Th>Count</Th>
                <Th>Name</Th>
                <Th>SKU (Product Code)</Th>
                <Th>Price</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {order.products.map((product, i) => {
                return (
                  <Tr key={i}>
                    <Td>{product.count}</Td>
                    <Td>{product.product.name}</Td>
                    <Td>{product.product.sku}</Td>
                    <Td>${product.product.price}</Td>
                    <Td>${product.product.price * product.count}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {/* {order.products.map(product => {
            return <Text>{product}</Text>;
          })}
          <Text>
            <pre>{JSON.stringify(order.products, null, 1)}</pre>
          </Text> */}
        </Box>
      </Box>
    </Card>
  );
};

export default ViewOrderCard;
