import {
  Box,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  FaFacebookSquare,
  FaInstagram,
  FaWhatsapp,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Links from '../../layouts/MainLayout/Links';

export const MainFooter = () => {
  return (
    <Box bg="gray.800" color="white">
      <Wrap mx="2" mt="6" minH="60">
        <Spacer></Spacer>
        <WrapItem w={{ base: '100%', md: '30%' }}>
          <Box py={{base:"4",md:"8"}} fontSize={{base:"sm",md:"md"}}>
            <Heading
              as="h3"
              fontSize="2xl"
              mb={{base:"3",md:"6"}}
              fontFamily="playfair display"
              fontWeight="100"
            >
              Links
            </Heading>
            {Links.map((link, i) => {
              return (
                <Text key={i}>
                  <Link to={link.url}>{link.name}</Link>
                </Text>
              );
            })}
          </Box>
        </WrapItem>
        <WrapItem w={{ base: '100%', md: '30%' }}>
          <Box py={{base:"4",md:"8"}} fontSize={{base:"sm",md:"md"}}>
            <Heading
              fontSize="2xl"
              as="h3"
              mb={{base:"3",md:"6"}}
              fontFamily="playfair display"
              fontWeight="100"
            >
              Get In Touch
            </Heading>
            <Text>+263 774 161 459, +236 242 320 310</Text>
            <Text>admin@cater4u.co.zw</Text>
            <Text>
              <a
                href="https://wa.me/263774161459?text=I'm%20inquiring%20about%20your%20services"
                target="_blank"
                rel="noreferrer"
              >
                <Icon as={FaWhatsapp} /> +263 774 161 459
              </a>
            </Text>
            <HStack>
              <Box>
                <a href="https://www.facebook.com/cater4u2019/" rel="noreferrer" target="_blank">
                  <Icon as={FaFacebookSquare} />
                </a>
              </Box>
              <Box>
                <a href="https://www.instagram.com/cater_4_you/" rel="noreferrer" target="_blank">
                  <Icon as={FaInstagram} />
                </a>
              </Box>
            </HStack>
          </Box>
        </WrapItem>
      </Wrap>
      <Box bg="gray.900">
        <Text textAlign="center" fontSize="sm" py="4">
          Copyright Cater 4 U 2021
        </Text>
      </Box>
    </Box>
  );
};