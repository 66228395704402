/**
 *
 * the nav item is the individual link dislayed in the sidebar
 *
 */
import { Flex, Link } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface ComponentProps{
    icon: any,
    url: string,
    children: React.ReactChild | React.ReactChildren;
}

const NavItem = ({ icon, url, children, ...other }:ComponentProps) => {
  const location = useLocation();

  const activeStyle = {
    bg: 'blue.300',
    color: 'blue.900',
    _after: {
      content: `""`,
      top: 0,
      right: 0,
      position: 'absolute',
      width: '3px',
      height: '100%',
      bg: 'blue.900',
      display: 'block',
      opacity: '0.6',
    },
  };

  const style = location?.pathname.includes(url) ? activeStyle : {};

  return (
    <Link
      style={{ textDecoration: 'none' }}
      as={RouterLink}
      to={url}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        px="8"
        py="4"
        role="group"
        {...style}
        cursor="pointer"
        _hover={activeStyle}
        position="relative"
        {...other}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'blue.900',
            }}
            as={icon}
          />
        )}
        {location.pathname.includes(url)}
        {children}
      </Flex>
    </Link>
  );
};

export default NavItem;