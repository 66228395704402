import { Box, Button, Text, Image, Divider } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  getBanner,
  uploadBannerImage,
} from '../../../../services/banners.service';
import Card from '../../../../components/Card';
import { Dropzone } from '../../../../components/Dropzone';
import { image_placeholder } from '../../../../common/Placeholders';
import { getImageURL } from '../../../../common/ImageHelpers';
import { IBanner } from '../../../../interfaces/BannerTypes';

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  position: Yup.number(),
});
interface IEditBannerCard {
  banner_id: number | string;
}

const EditBannerCard = ({ banner_id }: IEditBannerCard) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState();
  const [banner, setBanner] = useState<IBanner>();
  // const [value, setValue] = useState(banner?.position);
  // const handleChange = (valuesAsString: string, valueAsNumber: number) =>
  //   setValue(valueAsNumber);
  const [key, setKey] = useState(1);

  function getImages(images: any) {
    setImages(images);
  }

  function formatFormdata(data: any) {
    const formData = new FormData();
    formData.append('image', data ? data[0] : null);
    return formData;
  }

  interface MyFormValues {
    name: string;
    description: string;
  }
  const initialValues: MyFormValues = {
    name: banner ? banner?.name : '',
    description: banner ? banner?.description : '',
  };

  async function submit(data: any) {
    setLoading(true);
    var image = formatFormdata(images);
    // if (!images || typeof images[0] === 'undefined') {
    // if (!images) {
    //   toast.error('image required');
    //   setLoading(false);
    //   return;
    // }

    // var id = await updateBanner(banner_id, data)
    //   .then((response: any) => {
    //     setKey(key ? 0 : 1);
    //     toast.info('Banner Updated');
    //     setBanner(response?.data?.data);
    //     // setValue(response?.data?.data?.position);
    //     return response?.data?.data?.id;
    //   })
    //   .catch((error: any) => {
    //     if (error?.response?.data?.data) {
    //       var errors = Object.values(error?.response?.data?.data);
    //       errors.forEach((value: any) => {
    //         toast.error(value[0]);
    //       });
    //     } else {
    //       toast.error('Error saving item');
    //     }
    //   });

    if (banner_id && images) {
      await uploadBannerImage(banner_id, image)
        .then((response: any) => {
          //console.log(response);
          setBanner(response?.data);
          // setValue(response?.data?.data?.position);
          toast.info('Image uploaded');
        })
        .catch((error: any) => {
          toast.error('error uploading image');
        });
    }

    setKey(key ? 0 : 1);

    setLoading(false);
  }

  useEffect(() => {
    getBanner(banner_id)
      .then(response => {
        setBanner(response?.data?.data);
        // setValue(response?.data?.data?.position);
      })
      .catch(error => {
        toast.error('Error fetching banner');
      });
  }, [banner_id]);

  return (
    <Card
      width={{ base: '100%', md: '50%' }}
      mt="8"
      mb="4"
      px={{ base: '4', md: '4' }}
      borderRadius="md"
      shadow="md"
      py="4"
      key={key}
    >
      <Text fontWeight="bold">{banner?.name} - {banner?.description}</Text>
      <Divider my="4"/>
      <Box>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={values => {
            submit({ ...values });
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {/* <Field name="name">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                    isRequired
                    mt="8"
                  >
                    <FormLabel>Title</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="name"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isRequired
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                    mt="8"
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      placeholder="description"
                      {...field}
                      type="description"
                      _autofill={{
                        transition: 'all 5000s ease-in-out 0s',
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
               */}
              <Text mt="8">Image</Text>
              {banner?.image && (
                <Image
                  mt="8"
                  src={
                    banner.image ? getImageURL(banner.image) : image_placeholder
                  }
                  alt={banner.name}
                  fallbackSrc={image_placeholder}
                  width={{ base: '100%', md: '40%' }}
                  objectFit="cover"
                />
              )}
              <Dropzone getImages={getImages} mt="2"></Dropzone>

              <Button
                colorScheme="blue"
                // w="full"
                mt="6"
                type="submit"
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Card>
  );
};

export default EditBannerCard;
