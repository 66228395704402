import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Box, Button, Icon } from '@chakra-ui/react';
import * as React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import { ListDeliveryOptionsCard } from './ListDeliveryOptionsCard';

const ListDeliveryOptionsPage = () => {
  return (
    <Page title="list deliveryoptions">
      <Box>
      <Flex>
        <Text fontSize="2xl" fontWeight="bold">
          Delivery Options
        </Text>
        <Spacer />
        <Link to={RouteNames.admin.deliveryoptions+"/create"}>
          <Button colorScheme="green" size="sm">
            <Icon as={FiPlus} mr="1" /> Add DeliveryOption
          </Button>
        </Link>
      </Flex>
      <ListDeliveryOptionsCard />
      </Box>
    </Page>
  );
};

export default ListDeliveryOptionsPage;
