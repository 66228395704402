import * as React from 'react';
import loader from '../assets/images/loader.svg';
const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: 'white',
        zIndex: 10000,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img
          src={loader}
          alt=""
          style={{
            width: '40px',
            height: '40px',
            marginLeft:"auto",
            marginRight:"auto",
            marginBottom:"4px"
          }}
        />
        <p style={{
          color:"#BDB4B4"
        }}>Cooking up something...</p>
      </div>
    </div>
  );
};

export default Loader;
