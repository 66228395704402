import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';

interface ComponentProps {
  children: React.ReactNode;
  title: string
}

export const Page = ({ children, title = '', ...rest }:ComponentProps) => {
  return (
    <Box {...rest} style={{minHeight:"100vh"}}>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME + ' | ' + title}</title>
      </Helmet>
      <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
      {children}
      </motion.div>
      </AnimatePresence>
    </Box>
  );
};
