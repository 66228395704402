import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList, FiPlus } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import EditEventCard from './EditEventCard';
import { ListEventMenusCard } from './ListEventMenusCard';

export const EditEventPage = () => {
  let { event_id }: { event_id: any } = useParams();

  return (
    <Page title="add menu">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            Edit Event
          </Text>
          <Spacer />
          <Link to={RouteNames.admin.menus}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <Flex width="100%" mt="8" wrap="wrap">
          <Box width={{ base: '100%', md: '30%' }}>
            <EditEventCard event_id={event_id} mx="2" />
          </Box>
          <Box width={{ base: '100%', md: '70%' }} px="4">
          <Flex mt="4" mb="2">
              <Text fontSize="xl">
                Event Menus
              </Text>
              <Spacer />
              <Link to={RouteNames.admin.menus+'/create'}>
                <Button colorScheme="green" size="sm">
                  <Icon as={FiPlus} mr="1" />
                  Add Menu
                </Button>
              </Link>
            </Flex>
            <ListEventMenusCard event_id={event_id} mx="2" />
          </Box>
        </Flex>
      </Box>
    </Page>
  );
};

// import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
// import { FiList, FiPlus } from 'react-icons/fi';
// import { Link, useParams } from 'react-router-dom';
// import { RouteNames } from '../../../../common/RouteNames';
// import { Page } from '../../../../components/Page';
// import { ListEventMenusCard } from './ListEventMenusCard';

// export const EditEventPage = () => {
//   let { event_id }: { event_id: any } = useParams();

//   return (
//     <Page title="add menu">
//       <Box>
//         <Flex>
//           <Text fontSize="2xl" fontWeight="bold">
//             Edit Menu
//           </Text>
//           <Spacer />
//           <Link to={RouteNames.admin.events}>
//             <Button colorScheme="green" size="sm">
//               <Icon as={FiList} mr="1" />
//               Back to List
//             </Button>
//           </Link>
//         </Flex>
//         <Flex width="100%" mt="8" wrap="wrap">
//           <Box width={{ base: '100%', md: '100%' }} px="4">
//             <Flex mt="4" mb="2">
//               <Text fontSize="xl">
//                 Event Menus
//               </Text>
//               <Spacer />
//               <Link to={`${RouteNames.admin.menus}/${event_id}/courses`}>
//                 <Button colorScheme="green" size="sm">
//                   <Icon as={FiPlus} mr="1" />
//                   Add Course
//                 </Button>
//               </Link>
//             </Flex>
//             <ListEventMenusCard event_id={event_id} mx="2" />
//           </Box>
//         </Flex>
//       </Box>
//     </Page>
//   );
// };
