import { axiosInstance } from "../common/API";
const BASE_PATH = 'events';

interface DataType {
  [key: string]: string | boolean | number;
}

function getEvent(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getEvents() {
  return axiosInstance.get(`${BASE_PATH}`);
}

function getEventMenus(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}/menus`);
}

function getEventBySlug(slug: string){
  return axiosInstance.get(`${BASE_PATH}/slug/${slug}/menus`)
}

function storeEvent(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateEvent(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteEvent(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function uploadEventImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getEvent,
  getEventMenus,
  getEventBySlug,
  getEvents,
  storeEvent,
  updateEvent,
  deleteEvent,
  uploadEventImage,
};
