import { Box, Heading, Image, Stack, Text, Link } from '@chakra-ui/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { threeXtwo } from '../../../common/Assets';

interface ComponentProps {
  image: string;
  cta: string
  name: string;
  url: string;
}

export default function Card(data: ComponentProps) {
  return (
    <Link my="2" to={data.url} textDecor="none !important" as={RouterLink}>
      <Box
        maxW={'445px'}
        w={'full'}
        shadow="md"
        p="4"
        backgroundColor="gray.200"
        role="group"
      >
        <Box
          backgroundColor="gray.50"
          transition="all .4s ease"
          color="gray.700"
          border="solid 1px"
          boxShadow={'sm'}
          borderColor="gray.300"
          _groupHover={{
            shadow: "lg",
            colo: "gray.900"
          }}
          p={4}
          overflow={'hidden'}
          textDecor="none !important"
        >
          <Box
            // h={'210px'}
            mt={-6}
            mx={-6}
            mb={6}
            pos={'relative'}
            overflow="hidden"
          >
            <Image src={data.image} layout={'fill'} fallbackSrc={threeXtwo}/>
          </Box>
          <Stack>
            <Heading
              // color={useColorModeValue('gray.700', 'gray.900')}
              fontSize={'3xl'}
              fontFamily="playfair display"
              textAlign="center"
              fontWeight="200"
              textDecoration="none !important"
              _hover={{
                textDecor: 'none',
              }}
            >
              {data.name}
            </Heading>
            <Text
              textAlign="center"
              display="inline-block"
              mx="auto"
              transition="all .3s"
              _groupHover={{
                textDecoration: "underline"
              }}
              style={{ marginTop: '4.5em' }}
              fontFamily="playfair"
            >
              {data.cta}
            </Text>
          </Stack>
        </Box>
      </Box>
    </Link>
  );
}
