import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList } from 'react-icons/fi';
import { Link,useParams } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import EditCourseCard from './EditCourseCard';

export const EditCoursePage = () => {
  let { course_id }: { course_id: any } = useParams();
  let {menu_id}:{menu_id:any} = useParams();
  return (
    <Page title="edit course">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            Edit Course 
          </Text>
          <Spacer />
          <Link to={`${RouteNames.admin.menus}/${menu_id}`}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <EditCourseCard course_id={course_id} />
      </Box>
    </Page>
  );
};
