import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { Page } from '../../../../components/Page';
import { ListBannersCard } from '../../banners/ListBanners/ListBannersCard';

const Dashboard = () => {
  return (
    <Page title="dashboard">
      <Box>
      <Flex>
        <Text fontSize="2xl" fontWeight="bold">
          Dashboard
        </Text>
        <Spacer />
      </Flex>
      <ListBannersCard />
      </Box>
    </Page>
  );
};

export {Dashboard};
