import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import {
  FiArrowDown,
  FiArrowUp,
  FiChevronLeft,
  FiChevronRight,
  FiSearch,
} from 'react-icons/fi';
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import Card from './Card';

function fuzzyTextFilterFn(
  rows: Array<any>,
  id: number | string,
  filterValue: string
) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

const pageSizes = [5, 10, 15];

interface PropTypes {
  data: Array<any>;
  columns: Array<any>;
  edit_link: string;
}
export const TableCard = ({ data, columns, edit_link }: PropTypes) => {
  const filterTypes: any = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: Array<any>, id: number | string, filterValue: string) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
    // useGlobalFilter!
  );

  return (
    <Card>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />

      <Box overflow="auto" mt="4">
        <Table variant="simple" {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}{' '}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FiArrowDown style={{ display: 'inline-block' }} />
                      ) : (
                        <FiArrowUp style={{ display: 'inline-block' }} />
                      )
                    ) : (
                      ''
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={i}>
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </Box>

      <Flex mt="4" align="right">
        <Spacer></Spacer>
        <HStack spacing={4}>
          <Select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageSizes.map(item => {
              return (
                <option value={item} key={item}>
                  {item}
                </option>
              );
            })}
            {/* <option value="05">05</option>
                <option value="10">10</option>
                <option value="15">15</option> */}
          </Select>
          <Text whiteSpace="nowrap">
            Page {pageIndex + 1} of {pageOptions.length}
          </Text>
          <HStack spacing={1}>
            <IconButton
              isDisabled={!canPreviousPage}
              onClick={() => previousPage()}
              icon={<FiChevronLeft />}
              p="3"
              aria-label="navigate table left"
            />
            <IconButton
              isDisabled={!canNextPage}
              onClick={() => nextPage()}
              icon={<FiChevronRight />}
              p="3"
              aria-label="navigate table right"
            />
          </HStack>
        </HStack>
      </Flex>
    </Card>
  );
};

interface GlobalFilterTypes {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: GlobalFilterTypes) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Flex>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={FiSearch} color="gray.300" />}
        />
        <Input
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          width={{ base: '100%', md: '60' }}
          //   size="lg"
          placeholder={`Search ${count} records...`}
          _autofill={{
            transition: 'all 5000s ease-in-out 0s',
          }}
        />
      </InputGroup>
    </Flex>
  );
}
