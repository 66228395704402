import { Box, Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { FiList, FiPlus } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import EditMenuCard from './EditMenuCard';
import { ListCoursesCard } from './ListCoursesCard';

export const EditMenuPage = () => {
  let { id }: { id: any } = useParams();

  return (
    <Page title="add menu">
      <Box>
        <Flex>
          <Text fontSize="2xl" fontWeight="bold">
            Edit Menu
          </Text>
          <Spacer />
          <Link to={RouteNames.admin.menus}>
            <Button colorScheme="green" size="sm">
              <Icon as={FiList} mr="1" />
              Back to List
            </Button>
          </Link>
        </Flex>
        <Flex width="100%" mt="8" wrap="wrap">
          <Box width={{ base: '100%', md: '30%' }}>
            <EditMenuCard id={id} mx="2" />
          </Box>
          <Box width={{ base: '100%', md: '70%' }} px="4">
            <Flex mt="4" mb="2">
              <Text fontSize="xl">
                Menu Courses
              </Text>
              <Spacer />
              <Link to={`${RouteNames.admin.menus}/${id}/courses`}>
                <Button colorScheme="green" size="sm">
                  <Icon as={FiPlus} mr="1" />
                  Add Course
                </Button>
              </Link>
            </Flex>
            <ListCoursesCard menu_id={id} mx="2" />
          </Box>
        </Flex>
      </Box>
    </Page>
  );
};
