import { Box, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';
import corner from '../../../assets/images/frame/corner.jpg';
import horizontal from '../../../assets/images/frame/frame_horizontal.png';

interface ComponentProps {
  name: string;
  courses: Array<ICourse>;
}

interface ICourse{
  name: string,
  description: string
}

const Menu = ({ name, courses }: ComponentProps) => {
  return (
    <Box className="body dark-background">
      <Box className="outer-border">
        <Box className="">
          <Box className="inner-border" px="1">
            <img
              className="corner-decoration corner-left-top"
              src={corner}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-right-top"
              src={corner}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-right-bottom"
              src={corner}
              alt=""
            ></img>
            <img
              className="corner-decoration corner-left-bottom"
              src={corner}
              alt=""
            ></img>
            <img
              className="vertical-decoration top"
              src={horizontal}
              alt=""
            ></img>
            <img
              className="vertical-decoration bottom"
              src={horizontal}
              alt=""
            ></img>

            <Box className="container" my="20">
              <Box className="row">
                <Box className="col-lg-12 text-center" pb="20" style={{color:"white"}}>
                  <Text
                    width="full"
                    textAlign="center"
                    fontFamily="playfair display"
                    lineHeight={1.2}
                    my="4"
                    mt="6"
                    fontSize={useBreakpointValue({
                      base: '2xl',
                      md: '5xl',
                    })}
                    fontWeight="100"
                    mb="16"
                  >
                    {name}
                  </Text>
                  {courses.map((course:any, i) => {
                    return (
                      <div key={i}>
                        <CustomHeading>{course.name} {course.price && <span style={{fontSize:".7em"}}>(${course.price} pp) </span>}</CustomHeading>
                        <Text whiteSpace="pre-line" fontSize={{base:"xs",md:"md"}}>{course.description}</Text>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CustomHeading = ({ children }: {children:React.ReactNode}) => {
  return (
    <Heading
      fontFamily="playfair display"
      as="h3"
      textDecoration="underline"
      fontWeight="500"
      fontSize={{base:"md",md:"3xl"}}
      mb="4"
      mt="8"
      fontStyle="bold"
    >
      {children}
    </Heading>
  );
};

export default Menu;
