import { axiosInstance } from '../common/API';
const BASE_PATH = 'delivery-options';

interface DataType {
  [key: string]: string | boolean | number;
}

function getDeliveryOption(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getDeliveryOptions() {
  return axiosInstance.get(BASE_PATH);
}

function storeDeliveryOption(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateDeliveryOption(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteDeliveryOption(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

export {
  getDeliveryOption,
  getDeliveryOptions,
  storeDeliveryOption,
  updateDeliveryOption,
  deleteDeliveryOption,
};
