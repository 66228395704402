import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Box, Button, Icon } from '@chakra-ui/react';
import * as React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../common/RouteNames';
import { Page } from '../../../../components/Page';
import { ListMenusCard } from './ListMenusCard';

const ListMenusPage = () => {
  return (
    <Page title="list menus">
      <Box>
      <Flex>
        <Text fontSize="2xl" fontWeight="bold">
          Menus
        </Text>
        <Spacer />
        <Link to={RouteNames.admin.menus+'/create'}>
          <Button colorScheme="green" size="sm">
            <Icon as={FiPlus} mr="1" /> Add Menu
          </Button>
        </Link>
      </Flex>
      <ListMenusCard />
      </Box>
    </Page>
  );
};

export default ListMenusPage;
