import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  Icon,
  HStack,
  Spacer,
  Flex,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import store, { RootState } from '../../../common/store';
import { IHireItem } from '../../../interfaces/HireItemsInterface';
import { addItem, removeItem } from '../../../slices/HireItemsSlice';
import { useSelector } from 'react-redux';
import { RiShoppingCartFill } from 'react-icons/ri';
import { getImageURL } from '../../../common/ImageHelpers';
import { RouteNames } from '../../../common/RouteNames';
import { threeXtwo } from '../../../common/Assets';

// interface ComponentProps {
//   image: string;
//   cta: string
//   name: string;
//   url: string;
// }

export default function HireItemCard(data: IHireItem) {
  const [quantity, setQuantity] = React.useState(0);
  const products = useSelector((state: RootState) => state);

  React.useEffect(() => {
    var tmp = products.HireItemsSlice.filter(hireitem => {
      return hireitem.id === data.id;
    }).shift();
    setQuantity(tmp?.quantity ?? 0);
  }, [products, data]);
  function removeFromCart(hireitem: IHireItem) {
    store.dispatch(removeItem({ hireitem: hireitem }));
  }
  function updateCart(quantity: number) {
    quantity = quantity || 0;
    if (quantity < 1) {
      removeFromCart(data);
    } else {
      store.dispatch(addItem({ hireitem: data, quantity: quantity }));
    }
    // setQuantity(0);
  }

  return (
    // <Link
    //   my="2"
    //   to="#"
    //   w="100%"
    //   textDecor="none !important"
    //   as={RouterLink}
    //   // onClick={e => store.dispatch(addItem({ hireitem: data, quantity: 1 }))}
    // >
    <Box
      maxW={'445px'}
      w={'full'}
      shadow="md"
      // _hover={{
      //   backgroundColor: "gray.100"
      // }}
      p="4"
      backgroundColor="gray.200"
      role="group"
    >
      <Box
        backgroundColor="gray.50"
        transition="all .4s ease"
        color="gray.700"
        border="solid 1px"
        // _hover={{
        //   bg: '#00A44F',
        //   shadow: 'lg',
        //   color: '#fff',
        // }}
        boxShadow={'sm'}
        borderColor="gray.300"
        _groupHover={{
          shadow: 'lg',
          colo: 'gray.900',
        }}
        p={4}
        overflow={'hidden'}
        textDecor="none !important"
      >
        <Box
          // h={'210px'}
          mt={-6}
          mx={-6}
          mb={6}
          pos={'relative'}
          overflow="hidden"
        >
          <Image
            src={getImageURL(data.image)}
            layout={'fill'}
            fallbackSrc={threeXtwo}
          />
        </Box>
        <Stack>
          <Heading
            // color={useColorModeValue('gray.700', 'gray.900')}
            fontSize={'3xl'}
            fontFamily="playfair display"
            textAlign="center"
            fontWeight="200"
            textDecoration="none !important"
            _hover={{
              textDecor: 'none',
            }}
          >
            {data.name}
          </Heading>
          <Box>
            <Text mb="1em" textAlign="center">
              Minimum: {data.minimum}
            </Text>
            <Text mb="1em" textAlign="center">
              {data.description}
            </Text>
          </Box>
          <Divider variant="solid" borderColor="gray.500" my="4" />
          <Box>
            <HStack p="1">
              <Text fontFamily="playfair" fontSize="4xl" fontWeight="500">
                ${data.price}
              </Text>
              <Spacer />
              <Flex>
                <Text
                  whiteSpace="nowrap"
                  fontWeight="bold"
                  fontSize="2xl"
                  mr="3"
                  display="inline-block"
                >
                  <Tooltip label="Click to Go to the next step">
                    <RouterLink to={RouteNames.contact}>
                      <Icon as={RiShoppingCartFill} color="red.400" />
                    </RouterLink>
                  </Tooltip>
                </Text>
                <NumberInput
                  min={0}
                  width="5em"
                  borderRadius="0"
                  onChange={valueString =>
                    updateCart(parseInt(valueString, 10))
                  }
                  value={quantity}
                  defaultValue={0}
                  // size="sm"
                >
                  <NumberInputField borderRadius="0" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </Box>
    // </Link>
  );
}
