import { Box, Image, Link } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import moment from 'moment';
import { deleteHireItem, getHireItems } from '../../../../services/hireitems.service';
import { TableCard } from '../../../../components/TableCard';
import { getImageURL } from '../../../../common/ImageHelpers';
import { RouteNames } from '../../../../common/RouteNames';
import { image_placeholder } from '../../../../common/Placeholders';
const edit_link = RouteNames.admin.hireitems+'/';

export const ListHireItemsCard = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row: { original } }:{row:any}) => {
          return `$${original.price}` 
        },
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ row: { original } }:{row:any}) => {
          return (  
            <Image
              src={
                original.image ? getImageURL(original.image) : image_placeholder
              }
              alt={original.name}
              fallbackSrc={image_placeholder}
              boxSize="60px"
              objectFit="contain"
            />
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ row: { original } }:{row:any}) => {
          return moment(original?.created_at).format('MM/DD/YYYY');
        },
      },
      {
        disableSortBy: true,
        Header: 'Actions',
        accessor: 'actions',
        Cell: (tableProps:any) => (
          <>
            <Link
              as={RouterLink}
              to={edit_link + '' + tableProps.row.original.id}
              color="blue.500"
            >
              Edit
            </Link>{' '}
            <Link
              color="red.500"
              onClick={() => {
                swal({
                  title: 'Are you sure?',
                  text: 'Once deleted, you will not be able to recover this item!',
                  icon: 'warning',
                  buttons: [true,true],
                  dangerMode: true,
                }).then(willDelete => {
                  if (willDelete) {
                    deleteHireItem(tableProps.row.original.id)
                      .then(() => {
                        const dataCopy = [...data];
                        dataCopy.splice(tableProps.row.index, 1);
                        setData(dataCopy);
                        toast.info('Item removed');
                      })
                      .catch((error: Object | null) => {
                        toast.error('error removing Item');
                      });
                    swal('Item deleted!', {
                      icon: 'success',
                    });
                  } else {
                    // swal('Your imaginary file is safe!');
                  }
                });
              }}
            >
              Delete
            </Link>
          </>
        ),
      },
    ],
    [data]
  );



  useEffect(() => {
    getHireItems()
      .then((response: any) => {
        //console.log(response?.data?.data)
        setData(response?.data?.data);
      })
      .catch((error: any) => {
        //console.log(error?.response);
      });
  }, []);

  return (
    <Box>
      <TableCard
        data={data}
        columns={columns}
        edit_link={edit_link}
      ></TableCard>
    </Box>
  );
};
