import { axiosInstance } from '../common/API';
const BASE_PATH = 'products';
const types = ['shop','catertogo']

interface DataType {
  [key: string]: string | boolean | number;
}

function getProduct(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}`);
}

function getProductCourses(id: string | number) {
  return axiosInstance.get(`${BASE_PATH}/${id}/courses`);
}

function getProducts(type='') {
  var path = types.includes(type)? `${BASE_PATH}?type=${type}`: `${BASE_PATH}`
  return axiosInstance.get(path);
}

function storeProduct(data: DataType) {
  return axiosInstance.post(`${BASE_PATH}`, { ...data });
}

function updateProduct(id: string | number, data: DataType) {
  return axiosInstance.put(`${BASE_PATH}/${id}`, { ...data });
}

function deleteProduct(id: string | number) {
  return axiosInstance.delete(`${BASE_PATH}/${id}`);
}

function updateProductEvents(id: string | number, data: DataType) {
  //console.log(data)
  return axiosInstance.post(`${BASE_PATH}/${id}/events`,{...data});
}

function uploadProductImage(id: string | number, data: FormData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axiosInstance.post(`${BASE_PATH}/${id}/upload-image`, data, config);
}

export {
  getProduct,
  getProducts,
  storeProduct,
  updateProduct,
  updateProductEvents,
  getProductCourses,
  deleteProduct,
  uploadProductImage,
};
