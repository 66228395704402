import {
  Box,
  // Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Page } from '../../../components/Page';
// import HIreItemCard from './HireItemCard';
import { useContext } from 'react';
import { getHireItems } from '../../../services/hireitems.service';
import HireItemCard from './HireItemCard';
import { IHireItem } from '../../../interfaces/HireItemsInterface';
import { BannerContext } from '../../../layouts/MainLayout';
import { getBannerImageByName } from '../../../common/ImageHelpers';
import Loader from '../../../components/Loader';
import { useQuery } from 'react-query';
import LovinglyMade from '../../../components/LovinglyMade';
import { EventHeader } from '../../../components/EventHeader';
import FAB from '../../../components/Buttons/FAB';
import { RootStateOrAny, useSelector } from 'react-redux';
// import {useSelector} from "react-redux"

export const Hire = () => {
  // const products = useSelector((state: IHireItem[]) => state)
  const banners = useContext(BannerContext);
  const { isLoading, data } = useQuery('hireitems', getHireItems);
  const cart = useSelector((state: RootStateOrAny) => state);

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <Page title="Amazing Catering in Harare">
      <Box>
        <EventHeader
          title={'Hire It'}
          subtitle={
            'Do you Need, It? We Got it Browse our catalogue and hire what you need. We have all the essentials required for your special function.'
          }
          backgroundImage={getBannerImageByName('hirepagebanner', banners)}
        />
        {/* <pre>{JSON.stringify(cart.HireItemsSlice,null,1)}</pre> */}
        {cart.HireItemsSlice.length > 0 && <FAB link="contact" />}
        <Box w="" mx={{ base: '1', md: '1' }} mt="10">
          <Wrap w="" justify="center">
            {data?.data?.data.map((hireitem: IHireItem, i: any) => {
              return (
                <WrapItem
                  key={i}
                  w={{ base: '100%', md: '30%' }}
                  px="2"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  py={2}
                >
                  <HireItemCard
                    id={hireitem.id}
                    price={hireitem.price}
                    minimum={hireitem.minimum}
                    quantity={0}
                    name={hireitem.name}
                    description={hireitem.description}
                    image={hireitem.image}
                  ></HireItemCard>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
        <LovinglyMade />
      </Box>
    </Page>
  );
};
