import { Box, Image, Link } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { RouteNames } from '../../../../common/RouteNames';
import { getImageURL } from '../../../../common/ImageHelpers';
import { image_placeholder } from '../../../../common/Placeholders';
import { getBanners } from '../../../../services/banners.service';
import { TableCard } from '../../../../components/TableCard';
const edit_link = RouteNames.admin.banners + '/';

export const ListBannersCard = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ row: { original } }: { row: any }) => {
          return (
            <Image
              src={
                original.image ? getImageURL(original.image) : image_placeholder
              }
              alt={original.name}
              fallbackSrc={image_placeholder}
              boxSize="60px"
              objectFit="contain"
            />
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ row: { original } }: { row: any }) => {
          return moment(original?.created_at).format('MM/DD/YYYY');
        },
      },
      {
        disableSortBy: true,
        Header: 'Actions',
        accessor: 'actions',
        Cell: (tableProps: any) => (
          <>
            <Link
              as={RouterLink}
              to={edit_link + '' + tableProps.row.original.id}
              color="blue.500"
            >
              Edit
            </Link>{' '}
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getBanners()
      .then((response: any) => {
        //console.log(response);
        setData(response?.data);
      })
      .catch((error: any) => {
        // //console.log(error?.response);
      });
  }, []);

  return (
    <Box>
      <TableCard
        data={data}
        columns={columns}
        edit_link={edit_link}
      ></TableCard>
    </Box>
  );
};
