import {
  Box,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import {  useQuery } from 'react-query';
import { AdminRoutes } from '../../routes/AdminRoutes';
import { getCurrentUser } from '../../services/users.service';
import SidebarContent from './SidebarContent';
import MainNav from './MainNav';
import { MinimalFooter } from '../../components/footers/MinimalFooter';
import Loader from '../../components/Loader';

export const AdminLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, error, data } = useQuery(
    'currentUser',
    getCurrentUser
  );

  if (error instanceof Error) return <div>{'An error has occurred: ' + error.message}</div>;
  return (
    <Box minH="100vh" bg={'gray.100'}>
      {isLoading && <Loader />}
      <SidebarContent onClose={onClose}></SidebarContent>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MainNav onOpen={onOpen} user={data?.data?.data} />
      <Box ml={{ base: 0, md: 60 }}>
        {/* content is loaded here */}
        <Box p="4" pt="8" minH="100vh">
          <AdminRoutes />
        </Box>

        <MinimalFooter />
      </Box>
    </Box>
  );
};
