const admin_base = '/admin';

export const RouteNames = {
  admin: {
    menus: `${admin_base}/menus`,
    orders: `${admin_base}/orders`,
    courses: `${admin_base}/courses`,
    events: `${admin_base}/events`,
    shop: `${admin_base}/shop`,
    categories: `${admin_base}/categories`,
    banners: `${admin_base}/banners`,
    products: `${admin_base}/products`,
    hireitems: `${admin_base}/hireitems`,
    deliveryoptions: `${admin_base}/delivery-options`,
  },
  events: '/events',
  contact: '/contact',
  hireitems: '/hire',
  cart: '/cart',
  shop: '/shop',
  catertogo: '/cater-to-go',
};
